/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

import IconButton from '@mui/material/IconButton';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";

// types
import { UserDetail } from 'types/interface'

interface Props {
  user?: UserDetail,
  // onSubmitEdit: (data: string) => void,

}

function Notification( { user } :Props ): JSX.Element {


  const goEditUser = () => {
    // onSubmitEdit( 'true' )
  }

  // console.log(user.stats.confirmedIncidents)
    
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox        
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >    

        <MDBox p={1} pb={0}
          sx= {{ flex: "1 100%", }} > 

          {/* Header */}
          <MDBox 
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <MDTypography variant="h5">Notifikácie</MDTypography>

            {/* <MDTypography to='/' variant="body2" color="secondary">
              <Tooltip 
                title='edit' 
                placement="top"
                // onClick={goEditUser} 
                >
                <IconButton
                  className="edit"
                  aria-label="Edit"
                  size="small" >
                  <ManageAccountsIcon />
                </IconButton>
              </Tooltip>
            </MDTypography> */}
          </MDBox>

          <MDBox
            sx={{ display: "flex", flexDirections: "row", justifyContent: "space-between"  }}
          >
            <MDTypography variant="button" 
              >
              Hlásenie o výskyte:&nbsp;
            </MDTypography>
            { user.notificationsIsEnabled === 'Vypnuté' ? (
              <MDTypography variant="button" fontWeight="regular" 
                sx={{color:"#F44334"}} >
                &nbsp;{user.notificationsIsEnabled}
              </MDTypography>
            ) : user.notificationsIsEnabled === 'Zapnuté' ? (
              <MDTypography variant="button" fontWeight="regular" 
                sx={{color:"#4CAF50"}} >
                &nbsp;{user.notificationsIsEnabled}
              </MDTypography>
            ) : '-' }
          </MDBox>

        </MDBox>


        <Grid container pb={3} px={1} pt={0} mt={0}
          sx={{ width:"100%", }} >
          
          {/* lokalita */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}>            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                LOKALITA
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.5rem",} }
              fontWeight="regular" 
              color="text">
                {user ? user.location : '-'}
              </MDTypography>
            </MDBox>
          </Grid>   

          {/* radius hlaseni */}              
          <Grid 
            container
            xs={12} sm={12} >            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                RÁDIUS HLÁSENÍ
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.5rem",} }
              fontWeight="regular" 
              color="text">
                { user.radius ? user.radius : '-' }&nbsp;km
              </MDTypography>
            </MDBox>
          </Grid>             

        </Grid>
        
      </MDBox>
    </Card>
  );
}

export default Notification;
