

// axios
import { apiClient } from "api-client"

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz';

import { User } from 'types/index'

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

// Settings page components
import BasicInfo from "layouts/pages/report/addReport/components/BasicInfo";


function AddReport(): JSX.Element {

  const bratislavaTimeZone = 'Europe/Bratislava';

  const [ newId, setNewId ] = useState<number>(null);
  const [ type, setType ] = useState<string>('');
  const [ gpsLatitude, setGpsLatitude ] = useState<any>();
  const [ gpsLongitude, setGpsLongitude ] = useState<any>();
  const [ date, setDate ] = useState<string>('');
  const [ time, setTime ] = useState<string>('');
  const [ text, setText ] = useState<string>(null);
  const [ image, setImage ] = useState<File>(null);
  const [ dateTime, setDateTime ] = useState<any>('');
  
  const [ isAddReport, setIsAddReport ] = useState<boolean>( false );
  const [ isNewId, setIsNewId ] = useState<boolean>( false );

  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isError, setIsError ] = useState<boolean>( false )

  const navigate = useNavigate();


  // /*
  // */ Handle ISO date time
  //  
  
  const handleDateTime = () => {
    if (date && time) {
      // Ensure the 'time' value is in the "HH:mm" format
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (!timeRegex.test(time)) {
        console.log("Invalid time format");
        return;
      }

    // Combine date and time into ISO format
    const combinedDateTimeString = `${date}T${time}:00.000`;
    const combinedDateTime = new Date(combinedDateTimeString);
    const formattedDateTime = combinedDateTime.toISOString();
    setDateTime(formattedDateTime);
    }
  }
  
  
 // /*
 // */ Handle add report 
 // 
  const addReport = async ( param: string = '', imageQuery: File = null ) => {
    try {
      const params: any = {
        type: type,
        location: {
          name: '',
          coordinates: {
            latitude: gpsLatitude,
            longitude: gpsLongitude,
          },
        },
        description: text,
        occurredAt: dateTime,
      };

      // if have image handle to uuid format
      if ( param && ![null, undefined].includes(imageQuery) ) {
        // handle image to uuid
        const buffer = await imageQuery.arrayBuffer()

        const response = await apiClient.post( '/v1/upload', buffer, 
        {
          headers: {
            'Content-Type': 'application/octet-stream'
          }
        });
      
      const imageUuid = response.data; 

      params[param] = imageUuid;
      }
    
    await apiClient.post( '/v1/incidents', params )
    .then( ( response: any ) => {       
      setNewId(response.data.id)
      setIsNewId( true )
      setIsError( false )
    });
    } catch ( error: any ){
      if ( error && error.response.status === 404 && error.response.data.code === 'LocalityNotFoundError') {
        setErrorMessage(error.response.data.code);
        setIsError( true )
        console.log( error.message );
      } else {
        console.log( error.response.data.code , 'else' );
        setErrorMessage(error.response.data.code);
        // setIsError( true )
      }
    }
  }  

  // if click btn add, put data to server and go to 
  const handleSubmitAdd = ( verify: string ) => {
    if ( verify === 'true') { 
      handleDateTime();
      setIsAddReport( true );
    }
  } 

  // go to new report profile page
  const backProfile = () => {
    const id = newId;
    navigate(( `/pages/report/reportProfile/${ newId }` ), { state:{ id } });
  };


  // add report
  useEffect( () => {
    if ( isAddReport && dateTime ) {
      setIsAddReport( false );
      if ( image ){
        addReport( 'photo', image )         
      } else {
        addReport()        
      }
    }
  }, [ dateTime, isAddReport ]);
  
  // go to report profile
  useEffect( () => {
    if ( newId !== null && isNewId ) {
      backProfile()
      setIsNewId( false )
    }
  }, [ newId, isNewId ]);


  // translate report type
  const handleAddReportType = ( reportType: string ) => {
    if ( reportType && reportType === 'Stopy' ) {
      setType( 'tracks' )
    } else if ( reportType && reportType === 'Trus' ) {
      setType( 'scat' )
    } else if ( reportType && reportType === 'Medvedí strom' ) {
      setType( 'bear_tree' )
    } else if ( reportType && reportType === 'Stret' ) {
      setType( 'encounter' )
    } else {
      setType('')
    }
  }

  // get gps from child
  const handleAddReportGpsLatitude = ( reportGpsLatitude: number ) => {
    reportGpsLatitude ? setGpsLatitude( reportGpsLatitude ) : setGpsLatitude('');
  }
  
  // get gps from child
  const handleAddReportGpsLongitude = ( reportGpsLongitude: number ) => {
    reportGpsLongitude ? setGpsLongitude( reportGpsLongitude ) : setGpsLongitude('');
  }
  
  // get date from child
  const handleAddReportDate = ( reportDate: string ) => {
    reportDate ? setDate( reportDate ) : setDate('');
  }

  // get time from child
  const handleAddReportTime = async ( reportTime: string ) => {  
    reportTime ? setTime( reportTime ) : setTime('');
  }

  // get text from child
  const handleAddReportText = ( reportText: string ) => {
    reportText ? setText( reportText ) : setText('');
  }
  
  // get image from child
  const handleAddReportImage = ( reportImage: File ) => {
    reportImage ? setImage( reportImage ) : setImage(null)
    // setImage( reportImage )
  }

  // /*
  // */ Handle alert message 
  // 

  // handle alert close after click icon
  const handleNotClick = ( bool: boolean ) => {
    if ( bool === true ) setIsError( false )
  }

  // handle error messaage
  const alertContent = (name: string) => (
    <MDTypography variant="body2" color="white">
      
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      Lokalitu pre dané GPS súradnice sa nepodarilo nájsť
      </MDTypography>
      {/* . Give it a click if you like. */}
    </MDTypography>
  );
  
  return (
    <MDBox mt={3}
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto", 
        display: "flex", flexDirection: "row", justifyContent: "center", }}
    >     

      <MDBox mb={3}
        sx={{ width: "38rem", display: "flex", flexDirection: "column", alignItems: "center", }}
      >     

        {isError && 
          <MDAlert 
            sx={{ 
              width: "100%",
            }}
            color="error" 
            dismissible
            onNotClick={handleNotClick}
            >
            {alertContent("error")}
          </MDAlert>
        }
        
        <Grid item >
          <BasicInfo 
            onAddReportType={handleAddReportType}
            onAddReportGpsLatitude={handleAddReportGpsLatitude}
            onAddReportGpsLongitude={handleAddReportGpsLongitude}
            onAddReportDate={handleAddReportDate}
            onAddReportTime={handleAddReportTime}
            onAddReportText={handleAddReportText}
            onAddReportImage={handleAddReportImage}
            onSubmitAdd={handleSubmitAdd}

            type={ type }
            gpsLatitude={ gpsLatitude }
            gpsLongitude={ gpsLongitude }
            date={ date }
            time={ time }
            text={ text }
            image={ image }
          />
        </Grid>
      </MDBox>
              
    </MDBox>
  );
}

export default AddReport;
