/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// axios
import { apiClient } from "api-client"

import { Magic } from 'magic-sdk'

import { useEffect, useState } from "react";

import DOMPurify from "dompurify";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/MaL-cover-art-wide-Google.png";

import axios from 'axios'
import { display } from "@mui/system";

function Basic(): JSX.Element {

  const navigate = useNavigate()

  const [isLogin, setIsLogin] = useState( true );
  const [isLogout, setIsLogout] = useState(false);

  const [email, setEmail] = useState('');

  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isError, setIsError ] = useState<boolean>( false )

  // Function to send a magic link to the user's email
  async function sendMagicLink(email: string): Promise<void> {
    try {
      // Send a POST request to the API endpoint
      const response = await apiClient.post('/v1/auth/magiclink', { 
        destination: email,
        target: "admin",
        callbackUrl: `${window.location.origin}/authentication/sign-in/magiclink`
        }
      );

      // Handle the response
      console.log('Magic link sent successfully!');
      console.log(response);
      setIsLogin( false )
      setIsLogout(false)
    } catch ( error: any ){
      if ( error && error.response.status === 400 && error.response.data.code === 'AdminAccountRequiredError') {
        setErrorMessage(error.response.data.code);
        setIsError( true )
        console.log( error.message );
      } else {
        console.log( error.response.data.code , 'else' );
        setErrorMessage(error.response.data.code);
        // setIsError( true )
        // console.log(email)
      }
    }
  }

  const checkLocalStorage = ( key: any ) => {
    return localStorage.hasOwnProperty(key);
  };

 
  const isKeyPresent = checkLocalStorage('auth_token');

  const logOut = async () => {
    try {
      await apiClient.post( '/v1/auth/signout' )
      .then( response => {
        console.log( response )
        localStorage.removeItem('auth_token');
        setIsLogin( true )
      })      
    } catch {
      console.log( 'err' );
    }
  }

useEffect( () => {
  if ( isKeyPresent === true ) {
  logOut()
}
},[])

const handleSubmit = async (e: any) => {
  e.preventDefault();

  // console.log( email )
  sendMagicLink(email);
};

const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();

  let email = DOMPurify.sanitize( e.target.value );
  setEmail( email );
};

const handleNotClick = ( bool: boolean ) => {
  if ( bool === true ) setIsError( false )
}

const alertContent = (name: string) => (
  <MDTypography variant="body2" color="white">
    
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
    Na prihlásenie je potrebný účet správcu
    </MDTypography>
    {/* . Give it a click if you like. */}
  </MDTypography>
);

  return (
    <BasicLayout image={bgImage}>
      
        {isError && 
          <MDAlert 
            sx={{ 
              // position: "absolute", top: "50%", left: "5%"
              width: "30rem", marginBottom: "3rem"
            }}
            color="error" 
            dismissible
            onNotClick={handleNotClick}
            >
            {alertContent("error")}
          </MDAlert>
        }
      <Card
        sx={{
          width: "25rem"
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Prihlásenie
          </MDTypography>
          
        </MDBox>

        <MDBox pt={4} pb={3} px={3}
          sx={{
            minHeight:"11rem"
          }}
        >
          { isLogin && (
          <MDBox 
            component="form" 
            role="form"
            onSubmit={handleSubmit}
            >
            <MDBox mb={2}>
              <MDInput 
                type="email" 
                label="Email" 
                fullWidth
                onChange={handleEmailChange} />
            </MDBox>
            
            <MDBox mt={4} mb={1}>
              <MDButton 
                  variant="gradient" 
                  color="success" fullWidth
                  type="submit"
                  >
                Prihlásenie
              </MDButton>
            </MDBox>
          </MDBox>
          ) }

          { !isLogin && !isLogout && (
          <MDBox 
            align= "center"
            >
            <MDBox mb={2}>
              <MDTypography fontWeight= "medium" >
                Na Váš e-mail sme odoslali odkaz na prihlásenie do systému.
              </MDTypography>
            </MDBox>
            
           
          </MDBox>
          ) }
           
        </MDBox>

      </Card>
    </BasicLayout>
  );
}

export default Basic;
