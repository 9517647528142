

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import IconButton from '@mui/material/IconButton';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";


// types
import { UserDetail } from 'types/interface'

interface Props {
  user?: UserDetail,
  onSubmitEdit: (data: string) => void,

}

function PersonalInfo( { user, onSubmitEdit } :Props ): JSX.Element {


  const goEditUser = () => {
    onSubmitEdit( 'true' )
  }

     
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox        
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >    

        <MDBox p={1} pb={0}
          sx= {{ flex: "1 100%", }} > 

          {/* Header */}
          <MDBox 
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <MDTypography variant="h5">Osobné Informácie</MDTypography>

            <MDTypography  variant="body2" color="secondary">
              <Tooltip 
                title='edit' 
                placement="top"
                onClick={goEditUser} >
                <IconButton
                  className="edit"
                  aria-label="Edit"
                  size="small" >
                  <ManageAccountsIcon />
                </IconButton>
              </Tooltip>
            </MDTypography>
          </MDBox>

          <MDTypography variant="button" >
            Id používateľa:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{user.id}
          </MDTypography>
        </MDBox>


        <Grid container pb={3} px={1} pt={0} mt={0}
          sx={{ width:"100%", }} >
          
          {/* Meno pouzivatela */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}>            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "0.6rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                MENO
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                {user.fullName}
              </MDTypography>
            </MDBox>
          </Grid>   

          {/* Email pouzivatela */}              
          <Grid 
            container
            xs={12} sm={12} >            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                EMAIL
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                {user.email}
              </MDTypography>
            </MDBox>
          </Grid>      

          <Grid 
            container
            xs={12} sm={12} >            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                REGISTROVANÝ OD
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                {user.registeredAt}
              </MDTypography>
            </MDBox>
          </Grid>  

          {/* Rola + Overene hlasenia */}
          <Grid 
            container
            xs={12} sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              mt: "1.2rem",
              pr:"0.5rem"
            }} >            
            <MDBox 
              sx={{ 
                display: "flex",
                flexDirection: "column",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              }}>
              <MDTypography 
                sx={{                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                }} 
                variant="button"                   
                >
                ROLA
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { user.role }
              </MDTypography>
            </MDBox>

            <MDBox 
              sx={{ 
                display: "flex",
                flexDirection: "column",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              }}>
              <MDTypography 
                sx={{                         
                  mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                }} 
                variant="button"                   
                >
                OVERENÉ HLÁSENIA
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                {user.confirmedIncidents ? user.confirmedIncidents : 0}
              </MDTypography>
            </MDBox>

          </Grid>   

          {/* Stav + vsetky hlasenia */}
          <Grid 
            container
            xs={12} sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              mt: "1.2rem",
            }} >       

            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem",  
                  fontWeight: "500",
                  fontSize:"0.7rem",                      
                } } 
                variant="button"                   
                >
                STAV
              </MDTypography>   
              { user.blockedAt === 'Aktívny' ? (
              <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem", color: "#4CAF50", } }
                fontWeight="regular">
                { user.blockedAt }
              </MDTypography>
            ) : user.blockedAt === 'Blokovaný' ? (
              <MDTypography variant="button" 
                sx={ { marginTop: "0.2rem", color: "#F44334", } }
                fontWeight="regular">
                { user.blockedAt }
              </MDTypography>
            ) : '-' }           
              
            </MDBox>

            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  mt: "1.2rem",  
                  fontWeight: "500",
                  fontSize:"0.7rem",                      
                } } 
                variant="button"                   
                >
                VŠETKY HLÁSENIA
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { user.reportedIncidents ? user.reportedIncidents : 0 }
              </MDTypography>
            </MDBox>
          </Grid>        

        </Grid>



        
      </MDBox>
    </Card>
  );
}

export default PersonalInfo;
