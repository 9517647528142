

import React, { useRef } from 'react'

import { useNavigate } from "react-router-dom";

import DOMPurify from "dompurify";

// types
import { User } from 'types/index'

import dayjs from 'dayjs';
import { Dayjs } from 'dayjs'; // Import the Dayjs type
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { TimeField } from '@mui/x-date-pickers/TimeField';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "components/FormField";

// Data
import selectData from "layouts/pages/report/addReport/components/BasicInfo/data/selectData";

interface Props {
  onAddReportType: ( type: string ) => void,
  onAddReportGpsLatitude: ( gps: number ) => void,
  onAddReportGpsLongitude: ( gps: number ) => void,
  onAddReportDate: ( date: any ) => void,
  onAddReportTime: ( time: string ) => void,
  onAddReportText: ( text: string ) => void,
  onAddReportImage: ( file: File ) => void,
  onSubmitAdd: ( verify: string ) => void,

  type: string,
  gpsLatitude: number,
  gpsLongitude: number,
  date: string,
  time: string,
  text: string,
  image: File,
}

function BasicInfo({  
  onAddReportType, 
  onAddReportGpsLatitude,
  onAddReportGpsLongitude,
  onAddReportDate,
  onAddReportTime,
  onAddReportText,
  onAddReportImage,
  onSubmitAdd,

  type,
  gpsLatitude,
  gpsLongitude,
  date,
  time,
  image,
}: Props): JSX.Element {

  const navigate = useNavigate();

  const typeInput = useRef<HTMLInputElement>(null)
  const gpsInputLatitude = useRef<HTMLInputElement>(null)
  const gpsInputLongitude = useRef<HTMLInputElement>(null)
  const dateInput = useRef<HTMLInputElement>(null)  
  const timeInput = useRef<HTMLInputElement>(null)
  const textInput = useRef<HTMLInputElement>(null)
  const imageInput = useRef<HTMLInputElement>(null)

  const submitAdd = ( e: any) => {
    e.preventDefault();
    onSubmitAdd( 'true' );
  }
  
  // get data from role input
  const getInputType = ( e:any, newValue: any ) => {
    let type = DOMPurify.sanitize( newValue );
    onAddReportType( type )
  }
  
  // get data from gps input
  const getInputGpsLatitude = () => {
    let reportGpsLatitude = gpsInputLatitude.current?.value;
    const gpsValue = Number(reportGpsLatitude);
    onAddReportGpsLatitude(gpsValue);
  }
  
  // get data from gps input
  const getInputGpsLongitude = () => {
    let reportGpsLatitudeLongitude = gpsInputLongitude.current?.value;
    const gpsValue = Number(reportGpsLatitudeLongitude);
    onAddReportGpsLongitude(gpsValue);
  }

  const handleInputDateChange = (newDate: Dayjs | null) => {
      if ( newDate === null || isNaN(newDate.year()) || isNaN(newDate.month()) || isNaN(newDate.year()) ) {
      onAddReportDate('');
    } else {
      onAddReportDate( newDate.format('YYYY-MM-DD') );
    }
  };
  
  const getInputTime = ( newValue: any ) => {
    let hours = newValue?.$H.toString().padStart(2, '0');
    let minutes = newValue?.$m.toString().padStart(2, '0');
    let time = `${hours}:${minutes}`;

    if ( newValue === null || isNaN(newValue.$H) || isNaN(newValue.$m)) {
      onAddReportTime('')
    } else {
      onAddReportTime( time )
    }  
  }
  
  const getInputText = () => {
    let reportText = DOMPurify.sanitize( textInput.current?.value );
    onAddReportText( reportText )
  }
  
  const getInputImage = ( e: any ) => {
    let reportImage = e.target.files[0];
    onAddReportImage( reportImage )
  }
  
  // show button add report if have data
  const isVisible = ( type && gpsLatitude && gpsLongitude && date && time && image ) || 
                    ( ( type === "encounter" ) && gpsLatitude && gpsLongitude && date && time );

  const today = dayjs();

  return (
    
    <Card id="basic-info" 
      sx={{ overflow: "visible", width: "100%" }} >
      <MDBox p={3}>
        <MDTypography variant="h5">Pridať Hlásenie</MDTypography>
      </MDBox>      

      <MDBox container pb={3} px={3}
        sx={{
          display: "flex", flexDirection: "row", gap: "2rem"
        }}>
        <Grid container spacing={3}> 

          <Grid item xs={12} md={12} 
            sx={ {                         
              mt: "0.6rem"
            } }>
            <MDTypography 
              variant="button" >
              Typ hlásenia
            </MDTypography>  
            <Autocomplete             
              options={selectData.type}
              onChange={getInputType}
              renderInput={(params) => (
                <FormField {...params} 
                  InputLabelProps={{ shrink: true }}
                  inputRef={typeInput}                  
                  placeholder="Typ hlásenia"
                  /> )}
            />
          </Grid>          

          <Grid item xs={12} md={12} >
            <MDTypography variant="button" >
              GPS pozícia
            </MDTypography>
            <MDBox
              sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}
            >  
              <FormField 
                placeholder="zemepisná šírka"
                inputRef={gpsInputLatitude}
                onChange={getInputGpsLatitude}
              />
              <FormField 
                placeholder="zemepisná dĺžka"
                inputRef={gpsInputLongitude}
                onChange={getInputGpsLongitude}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={12} 
            sx={{
              display: "flex",
              flexDirection: "column",

            }}>
            <MDTypography variant="button" >
              Dátum udalosti
            </MDTypography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // defaultValue={today}
                maxDate={today}
                views={['year', 'month', 'day']}
                format="DD.MM.YYYY"
                inputRef={dateInput}
                onChange={handleInputDateChange}
              />
            </LocalizationProvider>

          </Grid>

          <Grid item xs={12} md={12} 
            sx={{
              display: "flex",
              flexDirection: "column",

            }}>
            <MDTypography variant="button" >
              Čas udalosti
            </MDTypography>  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField 
                format="HH:mm"
                inputRef={timeInput}
                onChange={getInputTime}
              />
            </LocalizationProvider>         
          </Grid>         
          
        </Grid>

        <Grid container spacing={3}>           

          <Grid item xs={12} md={12} 
            sx={{
              display: "flex",
              flexDirection: "column",

            }}>
            <MDTypography variant="button" >
              Textový popis
            </MDTypography>           
            <MDInput 
              type="text"
              multiline
              rows={6} 
              inputRef={textInput}
              onChange={getInputText}
              />
          </Grid>   

          <Grid item xs={12} md={12}
            sx={{
              marginTop: "-5rem"
            }}
            >
            <MDTypography variant="button">Pridať obrázok</MDTypography>
            <input
              type="file"
              accept="image/*"
              ref={imageInput}
              onChange={getInputImage}
              // onChange={handleImageChange}
            />
          </Grid>
          
        </Grid>
      </MDBox>

      <MDBox container 
        sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", 
            gap: "1rem", paddingRight: "1rem", paddingBottom: "1rem",
        }}       
      >
          <MDButton 
            variant="outlined" 
            color="success" 
            sx={{ 
              height: "50%",  
          }}    
            onClick={() => navigate(-1)}              
            >
            naspäť
          </MDButton> 
 
          <MDButton 
              variant="gradient" 
              color="success" 
              sx={{ height: "50%",
                visibility: isVisible ? "visible" : "hidden",            
              }}
              onClick={submitAdd}
              >
              Vytvoriť
            </MDButton>       
  
      </MDBox>     

    </Card>
  );
}

export default BasicInfo;
