

import { apiClient } from "api-client"

import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

// types
import { Query, Incidents, Users } from 'types/interface';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Timeline from 'components/Timeline';

import DeleteReport from "components/DeleteReport";

// Data
import { dataTableHeader, extractData } from "layouts/pages/report/reports-list/data/dataTableData";
import DataTable from "./data/DataTable";

// types
import { Reports } from "types/interface"

function ReportsList(): JSX.Element {

  // send data
  const navigate = useNavigate();

  let location = useLocation()

  const bratislavaTimeZone = 'Europe/Bratislava';

  const [ reports, setReports ] = useState<any>();
  const [ totalReports, settotalReports ] = useState<number>();
  
  const [ reportId, setReportId ] = useState<any>();
  
  const [ sortColumn, setSortColumn ] = useState<string>( 'desc' )
  const [ lastSortColumnType, setLastSortColumnType ] = useState<string>('id')
  const [ isSortColumn, setIsSortColumn ] = useState<boolean>(false)
  
  // handle rowLimit
  const defaultRowLimit = 10;
  const storedRowLimit = JSON.parse(localStorage.getItem('rowLimitReports'));
  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : defaultRowLimit;
  const [ rowLimit, setRowLimit ] = useState<number>( initialRowLimit );
  // If change default value entries of pages set them to localStorage
  localStorage.setItem('rowLimitReports', JSON.stringify(rowLimit));

  const [ page, setPage ] = useState<number>(0);
  const [ numberOfPages, setnumberOfPages ] = useState<number>( );
  const [ offset, setOffset ] = useState<number>( 0 );

  const [ isReturningFromEdit, setIsReturningFromEdit ] = useState(false);
  const [ isReturningLimitRow, setIsReturningLimitRow ] = useState(false);

  const [ isNextPage, setIsNextPage ] = useState(false);
  const [ isPreviousPage, setIsPreviousPage ] = useState(false);
  const [ isBtnPage, setIsBtnPage ] = useState(false);
  const [ isRowLimit, setIsRowLimit ] = useState<boolean>( false )

  const [ isDeleteReport, setIsDeleteReport ] = useState(false);

  // const for search
  const [ children, setChildren ] = useState<string>()
  const [ search, setSearch ] = useState<string | number>()
  
  const [searchValue, setSearchValue] = useState('');
  const [ filters, setFilters ] = useState<Filters>({});

  const [ searchId, setSearchId] = useState(''); 
  const [ searchType, setSearchType ] = useState<any>(''); 
  const [ searchStatus, setSearchStatus ] = useState<any>(''); 
  const [ searchFullName, setSearchFullName ] = useState<any>(''); 
  const [ searchOccurredAt, setSearchOccurredAt ] = useState<any>(null);
  const [ searchReportedAt, setSearchReportedAt ] = useState<any>(null) 

  const [ isNewFilter, setIsNewFilter ] = useState<boolean>( false );
  const [ isCleanInput, setIsCleanInput ] = useState<boolean>( false );
  
    
  interface GetReportsParams {
    offset: number;
    limit: number;
    sort: string;
    [key: string]: string | number;
  }
  interface Filters {
    [key: string]: string | number | boolean;
  }
 
  // /*
  // */ Handle reports list 
  // 

  // set filters to parameter for API
  const getFilters = () => {
    const additionalParams: any = {};
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        if ( key === 'id' && filters.id !== '' ) {
          let id = filters.id.toString();
          setSearchId( id );
        } else if ( key === 'type' && filters.type !== '' ) {
          let typeSk = translationTypeSk( filters.type )
          setSearchType( typeSk )
        } else if ( key === 'status' && filters.status !== '' ) {
          let statusSK = translationStatusSk( filters.status );
          setSearchStatus( statusSK )
        } else if ( key === 'reporter[contains]' ) {
          let fullName = filters['reporter[contains]'];
          setSearchFullName( fullName )
          // console.log( fullName )
        } else if ( key === 'occurredAt[gte]' ) {
          let occurredAt = "haveDate";
          setSearchOccurredAt( occurredAt )
        } else if ( key === 'reportedAt[gte]' ) {
          let reportedAt = "haveDate";
          setSearchReportedAt( reportedAt )
        }

        const filterValue = filters[key as keyof typeof filters];
        additionalParams[key] = filterValue;
      }
    }
    return additionalParams;
  };

  // Get reports list
  const getReports = async (
    param: string = '', 
    searchQuery: string | number = '', 
    additionalParams?: any) => {
    try {

      const params: GetReportsParams = {
        offset: offset,
        limit: rowLimit,
        sort: lastSortColumnType + ':' + sortColumn,
        ...getFilters(),
      };

      if ( param && ![null, undefined].includes(searchQuery) ) {
        params[param] = searchQuery;
      }

      if (additionalParams) {
        // Merge the additionalParams object into the params object
        Object.assign(params, additionalParams);
      }

      await apiClient.get('/v1/incidents',{
        params: params,
      }
      ).then( response => {
        let query: Reports[] = response.data.items.map(
           (query: Reports) => extractData( query ))
        
        let total = (response.data.totalCount);
        let numOfPages = Math.ceil(total / rowLimit);

        setReports( query );
        settotalReports( total );
        setnumberOfPages( numOfPages );
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }  

  const [ returningFromProfil, setReturningFromProfil ] = useState<boolean>( false );
  // get users after open page 
  useEffect(() => {
    if ( returningFromProfil === false && ( !location.state?.filter ) ) {
      getReports();
    }

    if (isReturningFromEdit || isReturningLimitRow) {
      setIsReturningFromEdit(false);
      setIsReturningLimitRow(false);
    }
  }, [isReturningFromEdit, isReturningLimitRow, returningFromProfil ]);  
  
  // get data from report profile
  useEffect(() => {
    if ( location.state !== null && location.state.filter ) {
      const filter = location.state.filter;
      setFilters(filter);
      const { filter: removedFilter, ...remainingState } = location.state;
      navigate("/pages/report/reports-list", { state: remainingState });
      setReturningFromProfil( true )
    }
  }, []);
  
  // get data if coming back from report profile
  useEffect(() => {
    if ( returningFromProfil === true ) {
      getReports();      
    }
  }, [ returningFromProfil ]);
  

  // on click btn pridat hlasenie go to page addReport
  const addNewReport = () => {
    navigate( ('/pages/report/addReport') ); 
  }

  // send data to profile page
  const handleProfileReport = ( data: Reports ) => {
    const id = data.id;
    const filter = filters
    navigate( (`/pages/report/reportProfile/${id}`), { state: { id, filter } } );
  }


  // /*
  // */ Handle search 
  // 

  // remove data from filters
  const removeFilter = (filter: string) => {
    if (filters.hasOwnProperty(filter)) {
      const updatedFilters = { ...filters };
      delete updatedFilters[filter];
      setFilters(updatedFilters);
    }
  };

  // remove data from filters
  const removeFilters = (filtersToRemove: string[]) => {
    const updatedFilters = { ...filters };

    filtersToRemove.forEach(filter => {
      if (updatedFilters.hasOwnProperty(filter)) {
        delete updatedFilters[filter];
      }
    });
    setFilters(updatedFilters);
  };

  // debounce if write to filter
  const handleInputBounce = (value: any) => {
    handleEnterSearch()    
  };

  // take data from child. searchId is as value in input
  const handleUserId = ( id: any ) => {
    setSearchId( id )
    handleSearchColumn( 'id', id )
    if ( id === '' ){
      removeFilter( 'id' )
    }
  }

  // take data from child. searchName is as value in input
  const handleUserName = ( name: string ) => {
    setSearchFullName( name )
    handleSearchColumn( 'reporter[contains]', name )
    if ( name === '' ){
      removeFilter( 'reporter[contains]' )
    }
  }

  // handle data after push enter
  const handleSearchColumn = ( column: any, data: string | number ) => {
    // Update filters for new search
    setFilters(prevFilters => ({
      ...prevFilters,
      [column]: data,
    }));
  }

  // if have date set data for ASC DESC and input
  const handleDateRange = ( date: any, children: any ) => {
    if ( date ) {
      if ( column === 'Dátum udalosti' ) {
        setSearchOccurredAt( date ) 
      } else if ( column === 'Nahlásené' ) {
        setSearchReportedAt( date )
      } else if ( column === 'exportCSV' ) {
        setDateFromCSV( date.startDate?.toISOString() )
        setDateToCSV( date.endDate?.toISOString() )
      }
    }
  }

  // handle range date after submit
  const handleSubmitDate = async ( bool: boolean, children: string ) => {

    if ( bool === true && column === 'Dátum udalosti' ){
      setFilters(prevFilters => ({
        ...prevFilters,
        'occurredAt[gte]': searchOccurredAt.startDate?.toISOString(), 'occurredAt[lte]': searchOccurredAt.endDate?.toISOString()
      }));
      setPage( 0 )
      setOffset( 0 )
      setIsNewFilter( true )  
      setIsDateWindow(false)   
    } else if ( bool === true && column === 'Nahlásené' ) {
      setFilters(prevFilters => ({
        ...prevFilters,
        'reportedAt[gte]': searchReportedAt.startDate?.toISOString(), 'reportedAt[lte]': searchReportedAt.endDate?.toISOString()
      }));
      setPage( 0 );
      setNewInputValue( 1 );
      setOffset( 0 );
      setIsNewFilter( true );
      setIsDateWindow( false );
    } else if ( column === 'exportCSV' ) {
      if ( dateToCSV && dateFromCSV ) {
        getCSV()
        setIsDateWindow( false );
      }
    }
  }
  
  // search after submit enter
  const handleEnterSearch =  () => {
    setPage( 0 );
    setNewInputValue( 1 ); 
    setOffset( 0 );
    setIsNewFilter((prevIsNewFilter) => !prevIsNewFilter);
  }

  // handle report type after choose type
  const handleReportType = ( type: string ) => {
    
    if ( type === 'clear' ) {
      removeFilter( 'type' );
      setSearchType('');
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        'type': translationType(type),
      }));
      setSearchType(type);
    }
    setPage( 0 );
    setNewInputValue( 1 );
    setOffset( 0 );
    setIsNewFilter( true ); 
  }

  // translate type sk->eng for search
  const translationType = ( type: string | number | boolean ) => {
    if ( type === 'Stopy' ) {
      return 'tracks';
    } else if ( type === 'Trus' ) {
      return 'scat';
    } else if ( type === 'Medvedí strom' ) {
      return 'bear_tree';
    } else if ( type === 'Stret' ) {
      return 'encounter';
    }
  }

  const translationTypeSk = ( type: string | number | boolean ) => {
    if ( type === 'tracks' ) {
      return 'Stopy';
    } else if ( type === 'scat' ) {
      return 'Trus';
    } else if ( type === 'bear_tree' ) {
      return 'Medvedí strom';
    } else if ( type === 'encounter' ) {
      return 'Stret';
    }
  }
 
  // handle report type after choose status
  const handleReportStatus =  ( status: string ) => {
    
    if ( status === 'clear' ) {
      removeFilter( 'status' );
      setSearchStatus('');
      console.log( status )
    } else {
      setFilters( prevFilters => ({
        ...prevFilters,
        'status': translationStatus( status ),
      }));
      setSearchStatus( status );
    }
    setPage( 0 );
    setNewInputValue( 1 );
    setOffset( 0 );
    setIsNewFilter( true );
  } 

  // translate report status sk->eng for search
  const translationStatus = ( status: string | number | boolean ) => {
    if ( status === 'Čaká na schválenie' ) {
      return 'pending';
    } else if ( status === 'Schválené' ) {
      return 'confirmed';
    } else if ( status === 'Zamietnuté' ) {
      return 'rejected';
    } 
  }  

  const translationStatusSk = ( status: string | number | boolean ) => {
    if ( status === 'pending' ) {
      return 'Čaká na schválenie';
    } else if ( status === 'confirmed' ) {
      return 'Schválené';
    } else if ( status === 'rejected' ) {
      return 'Zamietnuté';
    } 
  }  

   // getReports if have something in filter
   useEffect( () => {
    if ( isNewFilter === true ) {
      setIsNewFilter( false )
      getReports()
    }
  }, [ isNewFilter ]);

  // after submit search get reports and remove data from objects
  const handleClearInput = ( bool: boolean, children: string | number ) => {
    if ( bool === true ) { 
      if ( children === "Id" ) {
        removeFilter( 'id' );
        setSearchId('');
      } else if ( children === "Nahlasovateľ" ) {
        removeFilter( 'reporter[contains]' );
        setSearchFullName('');
      } else if ( children === "Dátum udalosti" ) {
        removeFilters(['occurredAt[gte]', 'occurredAt[lte]']);
        setSearchOccurredAt('');
      } else if ( children === "Nahlásené" ) {
        removeFilters(['reportedAt[gte]', 'reportedAt[lte]']);
        setSearchReportedAt('');
      }
      setIsNewFilter( true )
      setPage( 0 )
      setNewInputValue( 1 );
      setOffset( 0 )     
    }
  }

  // if click cross icon sort back acc id desc
  const handleClearInputs = ( bool: boolean ) => {
    if ( bool === true ) { 
      setSearchValue('');

      setSearchId('');
      setSearchType('');
      setSearchStatus('');
      setSearchFullName('');
      
      setSearchOccurredAt(null);
      setSearchReportedAt(null);
     
      setLastSortColumnType('id');
      setSortColumn('desc');
      setActiveColumn('');

      setFilters({});

      setPage( 0 );
      setNewInputValue( 1 ); 
      setOffset( 0 );

      setIsCleanInput(true);
    }
  }

  // getReports without filters
  useEffect( () => {
    if ( isCleanInput === true ) {
      setIsCleanInput(false);
      getReports()
    }
  }, [ isCleanInput, sortColumn, lastSortColumnType ]);

 
  // /*
  // */ Handle setting page 
  // 

  const [ isCombineSort, setIsCombineSort ] = useState<boolean>(false)

  // handle combine sort. Take data from child
  const handleCombineSort = ( 
    dataSearch: string | number,
    columnName?: string, 
    columnSort?: string, 
    sort?: string,
    ) => {
    
    setLastSortColumnType( translationColumn(columnSort) );
    setSortColumn( sort )
    setIsCombineSort( true )
  }

  // if have combine sort get reports
  useEffect( () => {
    if ( isCombineSort === true ) {
      setIsCombineSort(false);
      getReports()
    }
  }, [ isCombineSort, search, children, sortColumn, lastSortColumnType ]);


  const translationColumn = ( column: string ) => {
    if ( column === 'Id' ){
      return 'id'
    } else if ( column === 'Typ' ) {
      return 'type'
    } else if ( column === 'Lokalita' ) {
      return 'location'
    } else if ( column === 'Dátum udalosti' ) {
      return 'occurredAt'
    } else if ( column === 'Stav' ) {
      return 'status'
    } else if ( column === 'Nahlasovateľ' ) {
      return 'reporter'
    } else if ( column === 'Nahlásené' ) {
      return 'reportedAt'
    }
  }

  // Handle column sort
  const handleSortColumn = ( children: string, ascDesc: string ) => {
    if( children === 'Id' ){
      setLastSortColumnType( 'id' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Typ' ){
      setLastSortColumnType( 'type' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Lokalita' ){
      setLastSortColumnType( 'location' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Dátum udalosti' ){
      setLastSortColumnType( 'occurredAt' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Stav' ){
      setLastSortColumnType( 'status' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Nahlasovateľ' ){
      setLastSortColumnType( 'reporter' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Nahlásené' ){
      setLastSortColumnType( 'reportedAt' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } 
  }    

  // refresh if sort data
  useEffect( () => {
    if ( isSortColumn ) {
      setIsSortColumn( false );
      getReports()
    }
  }, [ isSortColumn ]);


  // ////// Handle if change row limit
  // 
  
  // take row limit from child
  const handleRowLimit = (num: number) => {
    let lastPage = Math.ceil(( totalReports / num ) - 1);

    setRowLimit(num)
    setIsRowLimit( true )

    setPage( 0 )
    setOffset( 0 ) 
    setNewInputValue( 1 )   
  }

   // refresh if change row limit
   useEffect( () => {
    if ( isRowLimit === true ) {
      getReports()
      setIsRowLimit( false )
      setActualPage()
    }
  }, [isRowLimit]);  


  // Handle actual page
  const setActualPage = () => {
    let actualPage =Math.ceil( offset / rowLimit );
    setPage( actualPage )
  }

  // after click on btn arrow next set offset
  const handleNextPage = ( boolValue: boolean ) => {
    // if ( boolValue === true && offset < ( (numberOfPages * 10) - 10 ) ){
    if ( boolValue === true ){
      let value = (+offset) + (+rowLimit)
      let actualPage = +page + 1 ;
      setOffset( value );
      setIsNextPage( true );
      setNewInputValue((+actualPage + 1));
    }
  }

  // refresh if click on arrow next
  useEffect( () => {
    if ( isNextPage === true ) {
      setIsNextPage(false);       
      if ( filters ){
        getReports()
        setActualPage()
      }
    }
  }, [offset, page, filters]);

  // after click on btn arrow previous set offset
  const handlePreviousPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset > 0 ){
      let offsetValue = +offset - rowLimit
      let actualPage = +page - 1 ;
      setOffset( offsetValue );
      setIsPreviousPage( true );  
      setNewInputValue((+actualPage + 1));
    }
  }

  // refresh if click on arrow previous
  useEffect( () => {
    if ( isPreviousPage === true ) {
      setIsPreviousPage(false);       
      if ( filters ){
        getReports()
        setActualPage()
      }
    }
  }, [offset, page, filters]);

  // after click on btn number set offset
  const handleBtnPage = ( btnPage: number ) => {
      let offsetValue = btnPage * rowLimit;
      setOffset( offsetValue );
      setIsBtnPage( true );         
  }

  // refresh if click on btn number
  useEffect( () => {
    if ( isBtnPage === true ) {
      setIsBtnPage(false);       
      if ( filters ){
        getReports()
        setActualPage()
      }
    }
  }, [offset, page, filters]);


  /////////////////// handle page if length pages > 6

  const [ newInputValue, setNewInputValue ] = useState( page + 1)
  const [ isEnter, setIsEnter ] = useState<boolean>(false)

   // take from child new number in input
  const handleInputValue = ( num: number ) => {
    setNewInputValue( num )
  }

  // if push enter, render entries acc input number
  const handleEnter = ( bool: boolean ) => {
    if ( bool === true ) {
      let newPage = +newInputValue - 1 ;
      let newOffset = +newPage * rowLimit ;
      setPage( newPage );
      setOffset( newOffset );
      setIsEnter(true);     
    }
  } 

  useEffect( () => {
    if ( isEnter === true ) {
      setIsEnter( false );
      if ( filters ){
        getReports()
        setActualPage()
      }
    }
  }, [isEnter, offset, page, filters]);

  // Handle active sorting arrows
  const [activeColumn, setActiveColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('');

  const handleAscDesc = (column:string, sort:string) => {
    setActiveColumn(column)
    setSortDirection(sort);
  };

  
  // /*
  // */ Handle delete report 
  //

  // if click on btn zrusit close the window
  const handleCloseWindow = ( bool: boolean ) => {
    if ( bool === true ) 
      setIsDeleteReport(false)
  }

  // handle btn delete report
  const handleBtnDeleteReport = ( bool: boolean ) => {
    if ( bool === true)
      deleteReport( reportId )
      setIsDeleteReport(false);
      // console.log('delete')
  }

  // Handle click on remove btn, open approve window and take id from child
  const handleIdDeleteReport = ( idReport: number ) => {
    setIsDeleteReport(true); 
    setReportId( idReport )   
  }

  // delete report
  const deleteReport = async ( id: number ) => {
    try {
      await apiClient.delete(`/v1/incidents/${id}`
      ).then( ( response ) => {
        // console.log( response )

        getReports()
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }

  // /*
  // */ Handle date for export csv
  //

  const [ dateFromCSV, setDateFromCSV ] = useState<any>()
  // Date Tue Aug 01 2023 00:00:00 GMT+0200 (Central European Summer Time)
  const [ dateToCSV, setDateToCSV ] = useState<any>()

  const [ isExportWindow, setIsExportWindow ] = useState<boolean>(false)

  const parseDateFromISO = ( dateString: any ) => {
    const date = parseISO(dateString);
    const zonedDate = utcToZonedTime(date, bratislavaTimeZone);
    
    return zonedDate;
  }

  // take data from input
  // const handleDateFrom = ( dateFrom: any ) => {
  //   setDateFromCSV(dateFrom["$d"])
  // }

  // // take data from input
  // const handleDateTo = ( dateTo: any ) => {
  //   setDateToCSV( dateTo["$d"] )
  // }

  // If click btn in window exportovat handle export data
  const handleBtnExport = ( bool: boolean ) => {
    getCSV()
  }
 
  // get csv
  const getCSV = async () => {
    try {
      const response = await apiClient.get('/v1/exports/incidents', {
        params: {
          start: dateFromCSV,
          end: dateToCSV,
        },
        responseType: 'blob', // Binary Large Object
      });
  
      // Create a temporary URL to download the CSV file
      const csvData = response.data;
      const url = window.URL.createObjectURL(new Blob([csvData]));
  
      // Create a link element and set the URL as the href attribute
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.csv');
  
      // Append the link to the document and click it to initiate the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up by removing the temporary URL object
      window.URL.revokeObjectURL(url);

      setIsExportWindow( false );
  
      console.log('CSV file downloaded successfully.');
    } catch (error) {
      console.log('Error');
    }
  };

  // If click btn exportovat hlasenia show window
  const exportReports = ( e: any ) => {
    e.preventDefault();

    // setIsExportWindow( true );
    setIsDateWindow( true );
    setColumn( 'exportCSV' )
  }

  // handle dateRange picker
  const [ column, setColumn ] = useState('');
  const [ isDateWindow, setIsDateWindow ] = useState<boolean>( false );

  const handleDateRangePicker = ( bool: boolean, children: string ) => {
    if ( bool === true ) setIsDateWindow( true ); setColumn( children )  
  }

  const handleCloseDateRange = ( bool: boolean ) => {
    if ( bool === false ) setIsDateWindow( false )
  }

  return (

    <MDBox 
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
      display: "flex", flexDirection: "column", justifyContent: "center", 
      marginTop: "1rem", marginBottom: "1rem",
      }}
    >

      <Grid display="flex" justifyContent="flex-end" gap="1rem"
      sx={{ pb: "1rem" }}
      >          
        <MDButton 
          variant="gradient" 
          color="light"
          onClick={exportReports}
          width={4} xs={2}>
            &nbsp;Exportovať hlásenia
        </MDButton>
        <MDButton 
          variant="gradient" 
          color="success"
          onClick={addNewReport}
          width={4} xs={2}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;Pridať hlásenie
        </MDButton>                    
      </Grid>

      <Card>
        <MDBox sx={{ display:"flex", flexDirection: "row", padding: "1.7rem 1.5rem 0.7rem 1.7rem"}}>
          <MDTypography variant="h5" fontWeight="medium">
            Hlásenia
          </MDTypography>            
        </MDBox>
        <DataTable 
          onProfileReport={ handleProfileReport }      
          onIdDeleteReport={ handleIdDeleteReport }
          onRowLimit={ handleRowLimit }
          rowLimit={ rowLimit }
          tableColumns={ dataTableHeader.columns } 
          tableRows={ reports ? reports : [] }
          totalReports={ totalReports }
          actualPage={ page }
          numberOfPages={ numberOfPages }
          onNextPage={ handleNextPage }
          onPreviousPage={ handlePreviousPage }
          onBtnPage={ handleBtnPage }
          onSortColumn={ handleSortColumn }
          onInputValue={ handleInputValue }
          newInputValue={ newInputValue }
          onEnter={ handleEnter }
          onClearInput={ handleClearInput }
          onClearInputs={ handleClearInputs }

          onEnterSearch={ handleEnterSearch }
          onUserId={ handleUserId }
          onUserName={ handleUserName }
          onReportType={ handleReportType }
          onReportStatus={ handleReportStatus }

          searchValue={ searchValue }

          searchId={ searchId }
          searchType={ searchType }
          searchOccurredAt={ searchOccurredAt }
          searchStatus={ searchStatus }
          searchFullName={ searchFullName }
          searchReportedAt={ searchReportedAt } 

          onCombineSort={ handleCombineSort }

          activeColumn={activeColumn}
          sortDirection={sortDirection}
          onAscDesc={handleAscDesc}

          onDateRangePicker={handleDateRangePicker}
          isDateWindow={ isDateWindow }

          onInputBounce={handleInputBounce}
          />

          { ( isDeleteReport === true ) ? 
          <DeleteReport
            onCloseWindow={handleCloseWindow}
            onBtnDeleteReport={handleBtnDeleteReport}
        /> : ''}
                    
      </Card>
      { ( isDateWindow ) && 
      <MDBox>          
        <Timeline
          onDateRange={ handleDateRange }
          onSubmitDate={ handleSubmitDate }
          onCloseDateRange={ handleCloseDateRange }
          column={ column }
          isDateWindow={ isDateWindow }
        />
      </MDBox>
      }

    </MDBox>

  );
}

export default ReportsList;
