import React, { useEffect } from 'react'

import { useNavigate } from "react-router-dom";

import { apiClient } from "api-client"

import queryString from "query-string";

//  Props
type Props = {
}

const Magic = (props: Props) => {

  const navigate = useNavigate()

    useEffect( () => {
       signin();
        
      },[]) 

// const signin = async () => {
//     const token = getToken();
//     const { data } = await apiClient.post('/v1/auth/signin', { token });
//     console.log( data.token );
//     }

  const homePage = () => {
    navigate('/pages/user/users-list');
  };

  const backSignin = () => {
    navigate('/authentication/sign-in');
  }

  const getToken = (): string | null => {
      return queryString.parse(window.location.search).token as string;
    }

  const signin = async () => {
  try {
    const token = getToken();   
      await apiClient.post('/v1/auth/signin',{ token }
      ).then( response => {
      let query = response.data.token as any;
        console.log(response.data.token)
      localStorage.setItem('auth_token', query )
      homePage()
      })
      .then(  );        
  } catch ( error ){
      console.log( 'err' );
      }
  }
    
  return (
    <div>
        ''
    </div>
  )
}

export default Magic