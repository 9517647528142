
import React, { useEffect, useState, } from 'react'

import { useLocation, useNavigate, useParams, } from "react-router-dom";

// axios
import { apiClient } from "api-client"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ApproveReport from "components/ApproveReport";
import RejectReport from "components/RejectReport";

// Settings page components
import ReportProperties from "layouts/pages/report/reportProfile/components/ReportProperties";

import Description from "layouts/pages/report/reportProfile/components/Description";

import DataTable from "./data/DataTable";

// types
import { ReportInfo } from 'types/interface'

import { extractReport } from 'layouts/pages/report/reportProfile/data/Data'
import { extractVotes, dataTableHeader } from 'layouts/pages/report/reportProfile/data/dataTableData'

// style
import 'css/_reportProfile.css'


function ReportProfile(): JSX.Element {

  // Take id from users-list
  let location = useLocation()

  const navigate = useNavigate();

  const { id } = useParams();

  const urlReportsList = '/pages/report/reports-list';

  const [ report, setReport ] = useState<any>(null);  
  const [ reportId, setReportId ] = useState<any>(null);  
  const [ votes, setVotes ] = useState<any>(null);
  const [ filters, setFilters ] = useState('')

  const [ isApproveReport, setIsApproveReport ] = useState<boolean>( false );
  const [ isRejectReport, setIsRejectReport ] = useState<boolean>( false );

  // /*
  // */ Handle approve/reject report 
  // 


  // send data back to report list
  const backReportList = () => {
    const filter = filters;
    const bool = true;
    navigate( (`/pages/report/reports-list`), { state: {filter} } );
  }

  // if click on btn zrusit close the window
  const handleCloseWindow = ( bool: boolean ) => {
    if ( bool === true ) 
      setIsApproveReport(false)
      setIsRejectReport(false)
  }

  // handle btn approve report
  const handleBtnApproveReport = ( bool: boolean ) => { 
    if ( bool === true )   
      approveReport( report.id );
      setIsApproveReport(false);      
  }

  // Handle block user
  const handleApproveReport = () => {
    setIsApproveReport(true);    
  }

  // approve report
  const approveReport = async ( id: number ) => {
    try {
      await apiClient.patch(`/v1/incidents/${id}`,{
          status: "confirmed",       
        }
      ).then( ( response: any ) => {
        // let query: any = response
        getReport(id)        
        // console.log(query)        
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }
  
  // ///////////////  reject

  // handle btn reject user
  const handleBtnRejectReport = ( bool: boolean ) => {
    if ( bool === true)
      rejectReport( report.id )
      setIsRejectReport(false);
      // console.log('reject')
  }

  // Handle reject user
  const handleRejectReport = () => {
    setIsRejectReport(true);       
  }

  // reject user
  const rejectReport = async ( id: number ) => {
    
    try {
      await apiClient.patch(`/v1/incidents/${id}`,{
        status: "rejected",
      }
      ).then( ( response ) => {
        // console.log( response )

        backTable()
        // getReport( id )
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }

  // Get report
  const getReport = async ( id: number ) => {
    try {
      await apiClient.get(`/v1/incidents/${id}/detail`
      ).then( ( response: any ) => {
        let query: ReportInfo = extractReport(response.data)           

        setReport(query);     
      });
    } catch ( error ){
        console.log( 'err' ) 
      }
  }  

  useEffect(() => {
    let reportId = parseInt(id)
    if ( reportId && reportId !== 0 && reportId !== null ) {
      setReportId( reportId )
      getReport( reportId )
      setFilters(location.state.filter)
    }
  },[ id, location.state.filter ]);

  // Get votes
  const getVotes = async ( id: number ) => {
    
    try {
      await apiClient.get(`/v1/incidents/${id}/votes`
      ).then( ( response: any ) => {
        let query: any = response.data.map( ( r:any ) => extractVotes( r ) )           

        let total = query.length
        let numOfPages = Math.ceil(total / rowLimit);

        setVotes(query);
        setTotalVotes( total );
        setnumberOfPages( numOfPages );
        setIsEnter( false )

        // console.log(query)        
      });
    } catch ( error ){
        console.log( 'err' ) 
      }
  }

  useEffect(() => {
    if( reportId && reportId !== null ) {
      getVotes(reportId)
    }
  },[reportId]);


   // back to table-users page
   const backTable = () => {
    navigate( urlReportsList );
  };

  // /*
  // */ Handle setting page 
  // 

  const [ totalVotes, setTotalVotes] = useState<any>(null);

  const [ sortColumn, setSortColumn ] = useState<string>( 'asc' )
  const [ lastSortColumnType, setLastSortColumnType ] = useState<string>('id')
  const [ isSortColumn, setIsSortColumn ] = useState<boolean>(false)
  const [ isEnter, setIsEnter ] = useState<boolean>(false)

  const [ page, setPage ] = useState<number>(0);
  const [ rowLimit, setRowLimit ] = useState<number>( 9 );
  const [ numberOfPages, setnumberOfPages ] = useState<number>( );
  const [ offset, setOffset ] = useState<number>( 0 );

  const [ isNextPage, setIsNextPage ] = useState(false);
  const [ isPreviousPage, setIsPreviousPage ] = useState(false);
  const [ isBtnPage, setIsBtnPage ] = useState(false);
  
  // take row limit from child
  const handleRowLimit = (num: number) => {
    setRowLimit(num)
    // console.log(rowLimit)
  }

  // Handle actual page
  const setActualPage = () => {
    let actualPage = ( offset / rowLimit );
    let page = actualPage + 1;
    setPage( actualPage )
    setNewInputValue( page )
  }

  // after click on btn arrow next set offset
  const handleNextPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset < ( (numberOfPages * 10) - 10 ) ){
      let value = offset + rowLimit
      setOffset( value );
      setIsNextPage( true );
    }
  }

  // refresh if click on arrow next
  useEffect( () => {
    if ( isNextPage === true ) {
      setIsNextPage(false);
      getVotes( reportId )
      setActualPage()
      // console.log( offset )
    }
  }, [offset,page]);

  // after click on btn arrow previous set offset
  const handlePreviousPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset > 0 ){
      let offsetValue = offset - rowLimit
      setOffset( offsetValue );
      setIsPreviousPage( true );      
    }
  }

  // refresh if click on arrow previous
  useEffect( () => {
    if ( isPreviousPage === true ) {
      setIsPreviousPage(false);
      getVotes( reportId )
      setActualPage()
      // console.log( offset )
    }
  }, [offset,page]);

  // after click on btn number set offset
  const handleBtnPage = ( btnPage: number ) => {
      let offsetValue = btnPage * rowLimit;
      setOffset( offsetValue );
      setIsBtnPage( true );         
  }

  // refresh if click on arrow previous
  useEffect( () => {
    if ( isBtnPage === true ) {
      setIsBtnPage(false);
      getVotes( reportId )
      setActualPage()
    }
  }, [offset,page]);

  // Handle column sort
  const handleSortColumn = ( column: string ) => {
    
    setLastSortColumnType( column )

    if ( lastSortColumnType === column && sortColumn === 'asc' ){
      setSortColumn( 'desc' )
      setIsSortColumn( true )
    } else {
      setSortColumn( 'asc' )
      setIsSortColumn( true )
    }
  }

  // refresh if sort data
  useEffect( () => {
    if ( isSortColumn ) {
      setIsSortColumn( false );
      getVotes( reportId )
    }
  }, [ isSortColumn ]);

  /////////////////// handle page if length pages > 6

  const [newInputValue, setNewInputValue] = useState( page + 1)

   // take from child new number in input
  const handleInputValue = ( num: number ) => {
    setNewInputValue( num )
    // console.log( num )
  }

  // if push enter, render entries acc input number
  const handleEnter = ( bool: boolean ) => {
    if ( bool === true ) {
      let newPage = newInputValue - 1 ;
      let newOffset = newPage * 10 ;
      setPage( newPage );
      setOffset( newOffset );
      setIsEnter(true);     
    }
  } 

   // refresh if push enter
   useEffect( () => {
    if ( isEnter ) {
      getVotes( reportId )
      // console.log(isEnter);
      // console.log(page);
      setIsEnter( false );

    }
  }, [ isEnter ]);

  // /*
  // */ Handle image
  // 

  const [imgsViewer, setImgsViewer] = useState<boolean | number>(false);

  const handleImgsViewer = ( bool: boolean ) => {
    if ( bool === true ) {
      setImgsViewer( true )
  } else if ( bool === false ) {
    setImgsViewer( false )
  }
  }

  const isVisible = ( report?.status !== "Schválené") && 
                    ( report?.status !== "Zamietnuté") && !isRejectReport;

  const isVisibleReject = ( report?.status !== "Schválené") && 
                          ( report?.status !== "Zamietnuté") && !isApproveReport

  return (
    <MDBox>

      <MDBox
        sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
        display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "1rem"
        }} 
      >
        
        <MDBox container
          sx={{
            pb: "1rem", display: "flex", direction: "row",
            gap: "1rem", width: "100%", 
          }}
          >            

            <MDBox 
              sx= {{
                display: "flex",    
                justifyContent: "flex-start",  
                width: "33%",              
              }} >
              <MDButton 
                variant= "outlined"
                color="success" 
                sx={{}}
                onClick={ backReportList }
                >
                Naspäť
              </MDButton>
            </MDBox>
            
            <MDBox sx= {{
                display: "flex", justifyContent: "flex-end", 
                gap: "1rem", width: "67%",                 
              }} >
           
              <MDButton 
                variant="gradient" 
                color="success" 
                sx={{ 
                  visibility: isVisible ? "visible" : "hidden", 
                }}
                onClick={handleApproveReport}
                >
                Schváliť
              </MDButton> 

              <MDButton variant="gradient" 
                color="error" 
                sx={{ 
                  visibility: isVisibleReject ? "visible" : "hidden", 
                }}
                onClick={handleRejectReport}
                >
                Zamietnuť
              </MDButton>

            </MDBox>
          </MDBox>
        
          <MDBox className="container-content"
            sx={{
              display: "flex",
              flexDirections: "row",
              paddingTop: "0rem"
            }}
          >      

            <MDBox className='properties'
              sx={{       
                display: "block",
                width: "33%",
                marginRight: "1rem",
                pb: "1rem",
              }} >
              <ReportProperties 
                report={report ? report : ''}
                />              
            </MDBox>     

            <MDBox sx={{ width: "67%" }} >
              <MDBox className='description' >
                <Description 
                  report={report ? report : ''}
                  imgsViewer={ imgsViewer }
                  onImgsViewer= { handleImgsViewer }
                />
              </MDBox>

              <MDBox className='verification'
                mb={1.5} mt={4} >         

                <DataTable
                  tableColumns={ dataTableHeader.columns }
                  tableRows={ votes ? votes : [] }
                  rowLimit={ rowLimit }
                  onRowLimit={ handleRowLimit }
                  totalReports={ totalVotes }
                  actualPage={ page }
                  countPages={ numberOfPages }
                  onNextPage={ handleNextPage }
                  onPreviousPage={ handlePreviousPage }
                  onBtnPage={ handleBtnPage }
                  onSortColumn={ handleSortColumn }
                  onInputValue={ handleInputValue }
                  newInputValue={ newInputValue }
                  onEnter={ handleEnter }
                />

              </MDBox>
            </MDBox>
          
        </MDBox>

        { ( isApproveReport === true ) ?
          <ApproveReport
          onCloseWindow={handleCloseWindow}
          onBtnApproveReport={handleBtnApproveReport}
          /> : '' }
        
        { ( isRejectReport === true ) ?
          <RejectReport
          onCloseWindow={handleCloseWindow}
          onBtnRejectReport={handleBtnRejectReport}
          /> : '' }

    </MDBox>
  </MDBox>

  );
}

export default ReportProfile;


