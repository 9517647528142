

// types
import { User } from 'types/interface'



// /*
// */ Data user
// 

// data user
export const extractUserEdit = ({
    id,
    fullName,
    email,
    role,
    blockedAt,
    stats: { confirmedIncidents, reportedIncidents },
    notifications: { isEnabled, location, radius },
  }: User) => {

    let locationName = location?.name;
    let locationCoordinatesLatitude = location?.coordinates.latitude;
    let locationCoordinatesLongitude = location?.coordinates.longitude;    
    let translateRole = translationRole( role );
    let translateblockedAt = translationblockedAt( blockedAt );    
    let notificationsEnabled = isEnabled !== null ? translationNotifications(isEnabled) : false;

    return {
      id,
      fullName,
      email,
      role: translateRole,
      blockedAt: translateblockedAt,
      confirmedIncidents,
      reportedIncidents,
      notificationsIsEnabled: notificationsEnabled,
      location: locationName,
      locationLatitude: locationCoordinatesLatitude,
      locationLongitude: locationCoordinatesLongitude,
      radius,
    };    
  };

  

// translate role eng => sk
const translationRole = ( userRole: string ) => {
    if ( userRole && userRole === 'visitor' ) {
        return 'Návštevník';
    } else if ( userRole && userRole === 'specialist' ) {
        return 'Odborník';
    } else if ( userRole && userRole === 'admin' ) {
        return 'Administrátor';
    }
}

// translate state eng => sk
const translationblockedAt = ( blockedAt: string | null ) => {
    return ( blockedAt === null ) ? "Aktívny" : "Blokovaný";
}

// translate notifications enabled eng => sk
const translationNotifications = ( notificationsIsEnabled: boolean ) => {
    return ( notificationsIsEnabled === true ) ? "Zapnuté" : "Vypnuté";
}
  

// export data
const Data = {
  extractUserEdit,
};
  
  export default Data;
