/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import ImgsViewer from "react-images-viewer";
import Stack from "@mui/material/Stack";


import { ReportInfo } from 'types/interface'

import { useState } from "react";

interface Props {
  report?: ReportInfo,
  imgsViewer: boolean | number,
  onImgsViewer: ( bool: boolean ) => void,
}

function Description( { report, imgsViewer, onImgsViewer } :Props ): JSX.Element {

  const openImgsViewer = () => onImgsViewer(true);
  const closeImgsViewer = () => onImgsViewer(false);

  
  return (
    <Card
      id="description-card"
      sx={{
        display: "flex", flexDirection: "row", 
        minHeight: "15rem",
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        className="card-header"
        sx={{
          display: "flex", justifyContent: "center", padding: "1rem 0rem 1rem 1rem", 
        }}
      >
        {report.photoSmall ? (
          <MDBox>
            <ImgsViewer
              imgs={[{ src: report.photoLarge } ] }
              isOpen={imgsViewer}
              onClose={closeImgsViewer}
              backdropCloseable              
            />
            <MDBox
              component="img"
              src={report ? report.photoSmall : '' }
              alt="Product Image"
              shadow="lg"
              borderRadius="lg"
              width="auto"
              maxWidth={"20rem"}
              height="inherit"
              maxHeight="20rem"
              style={{
                cursor: 'pointer',
              }}
              onClick={openImgsViewer}
            />                    
          </MDBox>
        ) : (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="gray.200"
            borderRadius="lg"
            width="100%"
            height="100%"
            color="gray.400"
            fontWeight="400"
            fontSize="L"
            sx={{ transform: "rotate(35deg)" }}
          >
            Obrázok nie je k dispozícii
          </MDBox>
        )}
        <MDBox textAlign="left" pt={0} pb={3} px={3} mt={-2}>
          <MDTypography variant="h6" sx={{ mt: 4 }}>
            Popis
          </MDTypography>
          <MDTypography variant="button" color="text" sx={{ mt: 1.5, mb: 1 }}>
            {report.description ? report.description : "-"}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Description;
