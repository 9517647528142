/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// icon
import BallotIcon from '@mui/icons-material/Ballot';

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "layouts/pages/report/reportProfile/data/DataTable/DataTableHeadCell";
import DataTableBodyCell from "layouts/pages/report/reportProfile/data/DataTable/DataTableBodyCell";

import { Reports } from "types/interface"


// Declaring props types for DataTable
interface Props {
  // entriesPerPage?:
  //   | false
  //   | {
  //       defaultValue: number;
  //       entries: number[];
  //     };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  // table: {
  //   columns: { [key: string]: any }[];
  //   rows: { [key: string]: any }[];
  // };
  tableColumns: { [key: string]: any }[];
  tableRows: { [key: string]: any }[];
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  totalReports: number;
  rowLimit: number;
  countPages: number;
  onRowLimit: ( num: number ) => void;
  actualPage: number;
  onBtnPage: ( key: number ) => void;
  onNextPage: ( boolValue: boolean ) => void;
  onPreviousPage: ( boolValue: boolean ) => void;
  onSortColumn: ( column: string ) => void;
  onInputValue: ( num: number ) => void;
  newInputValue: number;
  onEnter: ( bool: boolean ) => void;  
}

function DataTable({
  // entriesPerPage,
  canSearch,
  showTotalEntries,
  tableColumns,
  tableRows,
  pagination,
  isSorted,
  noEndBorder,

  totalReports,
  rowLimit,
  countPages,
  actualPage,
  onBtnPage,
  onRowLimit,
  onNextPage,
  onPreviousPage,
  onSortColumn,
  onInputValue,
  newInputValue,
  onEnter,
}: Props): JSX.Element {
  let defaultValue: any;
  let entries: any[];

  const entriesPerPage = rowLimit;
  
  // if (entriesPerPage) {
  //   defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : "10";
  //   entries = entriesPerPage.entries ? entriesPerPage.entries : ["10", "25", "50", "100"];
  // }

  if (entriesPerPage) {
    defaultValue = rowLimit;
  }

  const columns = useMemo<any>(() => tableColumns, [tableColumns]);
  const data = useMemo<any>(() => tableRows, [tableRows]);
 
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    // pageOptions,
    // canPreviousPage,
    // canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = tableInstance;

  const pageOptions: any = []  

  for (let i = 0; i <= countPages-1; i++) {
    pageOptions.push(i);
  }


  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => {setPageSize(value);
  }

   
  // Render the paginations
  const renderPagination = pageOptions.map((option: any) => (
    <MDPagination
      item
      className={ option }
      key={ option }
      onClick={
        // () => {gotoPage(Number(option))
                      ( (e:any) => handleBtnNumber( e, option ) )

      }
      // active={pageIndex === option}
      active={ ( actualPage  ) === option}
    >
      { option + 1 }
    </MDPagination>     

  ));

  const handleBtnNumber = ( e: any, key: any ) => {
    e.preventDefault();
    onBtnPage( key );
    console.log(key)
  }


  

  // const pageOptions = [0, 1, 2]

  // const renderPagination = arr.map((option: any) => (
  //   <MDPagination
  //     item
  //     key={option}
  //     onClick={() => gotoPage(Number(option))}
  //     active={pageIndex === option}
  //   >
  //     {option + 1}
  //   </MDPagination>     

  // ));

  
  // console.log( countPages )
  
  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }: any) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }: any) => gotoPage(Number(value.value - 1));
  // const handleInputPaginationValue = ({ target: value }: any) => console.log(Number(value.value - 1));

  

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    // console.log(sortedValue)

    return sortedValue;
  };

  // Setting the entries starting point
  // const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
  const entriesStart = actualPage === 0 ?actualPage + 1 :actualPage * pageSize + 1;

  // Setting the entries ending point
  // let entriesEnd;

  // if (pageIndex === 0) {
  //   entriesEnd = pageSize;
  // } else if (pageIndex === pageOptions.length - 1) {
  //   entriesEnd = rows.length;
  // } else {
  //   entriesEnd = pageSize * (pageIndex + 1);
  // }

  // Setting the entries ending point
  let entriesEnd;

  if (actualPage === 0) {
    entriesEnd = pageSize;
  } else if (actualPage === pageOptions.length - 1) {
    entriesEnd = totalReports;      
  } else {
    entriesEnd = pageSize * (actualPage + 1);      
  }

  // on click back to parent data
  const handleProfileClick = ( e:any, report: Reports ) => {
    e.preventDefault()
        // console.log( report.id )
    // onProfileReport( report )
  }
  
  // If click on edit btn send data to parent
  // const handleEditClick = ( e: React.MouseEvent<HTMLElement> , user: Users ) => {
  //   e.stopPropagation()

  //   onEditUser( user )
  // }

  // If click on block btn send data to parent
  // const handleBlockClick = ( e: React.MouseEvent<HTMLElement> , user: Users ) => {
  //   e.stopPropagation()
  //   console.log(user.blockedAt);
  //   (user.blockedAt === 'Blokovaný') ? onUnBlockUser( user ) : onBlockUser( user );
  //   // onBlockUser( user )
  // }

  // after change row limit send to parent number
  const getRowLimit = ( limit: number ) => {
    onRowLimit(limit)
  }  
  
  const SubmitNextPage = () => {
    onNextPage( true )
  }
  
  const SubmitPreviousPage = () => {
    onPreviousPage( true )
  }

  const toEntries = ( entries: number ) => {
    return entries
  }

  // if click on column header, send name of column parent 
  const handleColumnClick = ( e: any, column: any ) => {
    e.preventDefault()
    if ( column.id === 'fullName' ) {
      onSortColumn( 'reporter' )
    } else {
      onSortColumn( column.id )
    }    
  }

  // send parent new value in input
  const handleChange = (event: any ) => {
    const newValue = event.target.value;
    onInputValue(newValue);
  };

  // if click enter go to page in input page
  const handleKeyPress = ( event: any ) => {
    if (event.key === 'Enter') {
      onEnter( true )
    }
  };  

  // count how many is valid votes
  const numOfValid = ( validity: boolean ) => {
    let isValid = tableRows.map( val => val.isValid );

    let valid = isValid.reduce((acc, item) => {
      acc[item] = (acc[item] || 0) + 1;
      return acc;
    }, {});

    if ( validity === true ) {
      return valid["Schválené"] ? valid["Schválené"] : 0;
    } else {
      return valid["Neschválené"] ? valid["Neschválené"] : 0;
    }
  }
  
  // console.log(numOfValid(false))
    
  return (

    <TableContainer sx={{ boxShadow: "none", overflowX: "unset" }}> 

      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
         
          <MDBox
            sx={{ 
              p: 1, mt: -5, 
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl, }}
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor="info"
            color="white"
            width="4.5rem"
            height="4.5rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"          
          >
            <Icon><BallotIcon /></Icon>
          </MDBox>
        
          <MDBox
            sx={{ 
              display: "flex", flexDirection: "row", justifyContent: "space-between",
              alignItems: "center",width: "85%", }}>
            <MDBox ml={2} mt={0} lineHeight={0}>
              <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                Overenia
              </MDTypography>
              <MDTypography variant="button" fontWeight="light" color="text">
                Zoznam overení
              </MDTypography>
              {/* {members.length > -1 ? <MDBox display="flex">{renderMembers}</MDBox> : null} */}
            </MDBox>
            
            <MDBox ml={2} mt={0} lineHeight={0}
              sx={{ display: "flex", flexDirection: "row", gap: "1rem"}}
            >
              <MDTypography variant="button" fontWeight="regular" color="info">
                <span style={{ color: "#63B967", fontSize: "1.2rem" }}>
                  &#x25cf; 
                </span> 
                &nbsp;{numOfValid(true)}x
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                <span style={{ color: "#E93B77", fontSize: "1.2rem" }}>
                  &#x25cf;
                </span> 
                &nbsp;{numOfValid(false)}x
              </MDTypography>
              {/* {members.length > -1 ? <MDBox display="flex">{renderMembers}</MDBox> : null} */}
            </MDBox>
          </MDBox>
         
        </MDBox>              
      </MDBox>

      {/* Table header */}     
      <Table {...getTableProps()}>
        <MDBox component="thead" >
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => 
              ( 
                // console.log( column.Header )
                <DataTableHeadCell
                  key={key}
                  // {...column.getHeaderProps( (column.Header !== 'Lokalita') && isSorted && column.getSortByToggleProps() )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  // sorted={setSortedValue(column)}                  
                  // onClick={ (e:any)=>handleColumnClick( e, column) }
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}

            {/* Action column name */}
              {/* <DataTableHeadCell
                width="4%"
                align="center"
                sorted={null}
                >
                  Akcie</DataTableHeadCell> */}
            </TableRow>
          ))}
        </MDBox>

        <TableBody {...getTableBodyProps()} >
          
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow 
                className=""
                key={key} {...row.getRowProps()}>      

                
                {row.cells.map((cell: any, key: any) => (  
                  <DataTableBodyCell                     
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={                      
                    cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()} 
                    data-row-index={key}
                    // style={cellStyles}
                  >
                    {cell.value === "Schválené" ? (
                      <span style={{ color: "#4CAF50" }}>
                        &#x25cf; {/* Green dot */}
                      </span> 
                    ) : cell.value === "Zamietnuté" ? (
                      <span style={{ color: "#F44334" }}>
                        &#x25cf; {/* Red dot */}
                      </span>
                    ) : cell.value === "Čaká na schválenie" ? (
                      <span style={{ color: "#FB8C00" }}>
                        &#x25cf; {/* Red dot */}
                      </span>
                      ) : null}
                    {                    
                    (cell.render("Cell")                                       
                    )}
                  </DataTableBodyCell>                  
                ))}                
                {/* {(row.values.role !== "Administrátor") && (
                  <DataTableBodyCell
                    align="left"                    
                    >
                    <MDBox position="relative" padding="0rem 0rem 0rem 0rem" top="0">
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Upraviť">
                          <IconButton
                            className="edit"
                            // onClick={(e) => handleEditClick(e, row.values)}
                            aria-label="Edit"
                            size="small"
                          >
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Zahodť">
                          <IconButton 
                            aria-label="Remove" 
                            size="small"
                            // onClick={e => handleBtnRemoveReport( e, row.values )}
                            >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </MDBox>
                  </DataTableBodyCell>
                )}                 */}

              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Zobrazuje sa&nbsp;
              { (entriesStart > totalReports ) ? totalReports : entriesStart }
              &nbsp;až&nbsp;
              { (entriesEnd > totalReports ) ? totalReports : entriesEnd }
              &nbsp;z&nbsp;{totalReports}&nbsp;položiek
            </MDTypography>
          </MDBox>
        )}
        {/* { pagee > 1 && ( */}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {/* {canPreviousPage && ( */}
            { pageOptions.length > 1 && newInputValue > 1  &&(
              <MDPagination item onClick={() => {
                // previousPage()
                                                 SubmitPreviousPage()}}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="3rem" mx={1}>
                <MDInput
                  // inputProps={{ type: "number", min: 1, max: pageOptions.length }}
                  value={ newInputValue }
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                    handleChange(event)
                  }}
                  onKeyDown={handleKeyPress}                                  
                />
              </MDBox>
            ) : (
              renderPagination
            )}            
            {/* {canNextPage && ( */}
            { pageOptions.length > 1 && ( (actualPage + 1) < pageOptions.length ) &&(
              <MDPagination item 
                            onClick={() => {
                              // nextPage()
                                            SubmitNextPage()} }
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>

      {/* {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                  getRowLimit(newValue)
                  toEntries(newValue)
                  // countOfPages(newValue)
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;položiek na stránku
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null} */}
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
