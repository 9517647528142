
// axios
import { apiClient } from "api-client"

import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Settings page components
import BasicInfo from "layouts/pages/user/editUser/components/BasicInfo";

// data from userProfile
import { extractUserEdit } from "layouts/pages/user/editUser/data/Data";

// types
import { UserEdit } from 'types/interface'


function EditUser(): JSX.Element {

  const location = useLocation()

  const navigate = useNavigate();

  const { id } = useParams();

  const [ user, setUser ] = useState<any>(null);
  const [ userId, setUserId ] = useState<number>(null);

  const [ name, setName ] = useState<string>();
  const [ role, setRole ] = useState<string>();

  // /*
  // */ Handle user 
  //

  // Get user
  const getUser = async ( id: number ) => {
    try {
      const response = await apiClient.get(`/v1/users/${id}/detail`);
      const query: UserEdit = extractUserEdit(response.data);
      setUser( query )
      // console.log( response.data )
      } catch ( error ){
        console.log( 'err' )
      }
  }  

  useEffect(() => {
    let userId = parseInt(id)
    setUserId( userId )
    getUser( userId )
  },[id]);

  // get name from child
  const handleEditUserName = ( userName: string ) => {
    if ( userName ) { 
      setName(userName) 
    } else { setName('') }
  }

  // translate user role
  const handleEditUserRole = ( userRole: string ) => {
    if ( userRole && userRole === 'Návštevník' ) {
      setRole( 'visitor' );
    } else if ( userRole && userRole === 'Odborník' ) {
      setRole( 'specialist' )
    } else { setRole('') }
  }

  // put edit user data
  const putUser = async ( id: number ) => {
    try {
      await apiClient.patch(`/v1/users/${id}`,{
        fullName: name,
        role: role,
        }
      ).then( ( response: any ) => {       
        // console.log( role )
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }

  const [ isEdit, setIsEdit ] =useState<boolean>( false )

  // if click btn edit, put data to server and go to 
  const handleSubmitEdit = ( verify: string ) => {
    if ( verify === 'true') { 
      putUser(userId)
      setIsEdit( true )      
    }
  }

  useEffect(() => {
    if ( isEdit === true )
    backTable()
    setIsEdit( false ) 
  },[isEdit]);

 
  // back to table-users page
  const backTable = () => {
    const bool = true;
    navigate(( `/pages/user/userProfile/${userId}` ), { state:{ bool } });
  };

   
  return (
    <MDBox         
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
      display: "flex", flexDirection: "column", marginTop: "1rem",
      marginBottom: "1rem",
      }} 
    >
      <BasicInfo 
        user={ user ? user : '' }
        name={ name }
        role={ role }
        onEditUserName={ handleEditUserName }
        onEditUserRole={ handleEditUserRole }
        onSubmitEdit={ handleSubmitEdit }
      />
    </MDBox>
  );
}

export default EditUser;
