import React from 'react';
import { getGoogleMapsApiKey } from 'config';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface MapProps {
  center: {
    lat: number;
    lng: number;
  };
}

const MapComponent: React.FC<MapProps> = ({ center }) => {

  const googleMapsApiKey = getGoogleMapsApiKey();

  const mapOptions = {
    zoomControl: false, // Hide zoom control
    fullscreenControl: false, // Hide fullscreen control
    mapTypeControl: false, // Hide map type control
    streetViewControl: false, // Hide street view control
    gestureHandling: 'none', // Disable user gestures like zoom and pan
  }

  return (
    // <LoadScript googleMapsApiKey="AIzaSyDq0nmUn9Th6j8Z6RxCHkGgbLTJeszKXP4">
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '15rem' }}
        center={center}
        zoom={10}
        options={ mapOptions }
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;