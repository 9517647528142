
import React from 'react'

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";



type Props = {
  onCloseWindow: (bool: boolean) => void,
  onBtnDeleteReport: (bool: boolean) => void  
}

const DeleteReport = ( { onCloseWindow, onBtnDeleteReport }: Props) => {

  const closeWindow = () => {
    onCloseWindow( true );
  } 

  const submitBlock = () => {
    onBtnDeleteReport( true );
  }

  return (
    <Card 
        sx={ { 
          width: 1/4,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: "999"
          // backgroundColor: "#5ada55",
        } }
    >
          <MDBox 
            className='delete'
            sx={ {
              display: "flex",
              flexDirection: "column" ,
              justifyContent: "center" ,
              alignItems: "center" ,
              marginLeft: "0",
              // flexWrap: "wrap",
            } }
            >
            <MDBox p={3}>
              <MDTypography variant="h5">Naozaj chcete odstrániť hlásenie?</MDTypography>
            </MDBox>
          
            <MDBox 
              sx={ { 
                display:"flex",
                paddingBottom:"1rem", 
                gap:"0.8rem"
                
              } } 
            >
              <MDButton 
                variant="gradient" 
                color="light" 
                size="small"
                onClick={closeWindow}
                >
                Zrušiť
              </MDButton>

              <MDButton 
                variant="gradient" 
                color="error" 
                size="small"
                onClick={submitBlock}
                >
                Vymazať
              </MDButton>
            </MDBox>
            
          </MDBox>          
        </Card>  
  )
}

export default DeleteReport