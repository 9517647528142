
import { apiClient } from "api-client"

import { useState, useEffect, Children } from "react";

import { useNavigate, useLocation } from "react-router-dom";

// types
import { Query, Incidents } from 'types/interface';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BlockUser from "components/BlockUser";
import UnBlockUser from "components/UnBlockUser";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "layouts/pages/user/users-list/data/DataTable";

// Data
import { dataTableHeader, blockedAt } from "layouts/pages/user/users-list/data/dataTableData";

function UsersList(): JSX.Element {

  // send data
  const navigate = useNavigate();

  const location = useLocation();  
  
  const [ users, setUsers ] = useState<any>();
  const [ user, setUser ] = useState<any>();
  const [ totalUsers, settotalUsers ] = useState<number>();

  // handle rowLimit
  const defaultRowLimit = 10;
  const storedRowLimit = JSON.parse(localStorage.getItem('rowLimitUsers'));
  // If storedRowLimit is not null and is a valid number, use it as rowLimit; otherwise, use the default value.
  const initialRowLimit = storedRowLimit && !isNaN(storedRowLimit) ? storedRowLimit : defaultRowLimit;
  const [ rowLimit, setRowLimit ] = useState<number>( initialRowLimit );
  // If change default value entries of pages set them to localStorage
  localStorage.setItem('rowLimitUsers', JSON.stringify(rowLimit));

  const [ page, setPage ] = useState<number>(0);
  const [ numberOfPages, setNumberOfPages ] = useState<number>( );
  const [ offset, setOffset ] = useState<number>( 0 );

  const [ sortColumn, setSortColumn ] = useState<string>( 'desc' )
  const [ lastSortColumnType, setLastSortColumnType ] = useState<string>('id')
  const [ isSortColumn, setIsSortColumn ] = useState<boolean>(false)

  const [ isReturningFromEdit, setIsReturningFromEdit ] = useState<boolean>(false);
  const [ isReturningLimitRow, setIsReturningLimitRow ] = useState<boolean>(false);
  const [ returningFromProfil, setReturningFromProfil ] = useState<boolean>( false );

  const [ isNextPage, setIsNextPage ] = useState<boolean>(false);
  const [ isPreviousPage, setIsPreviousPage ] = useState<boolean>(false);
  const [ isBtnPage, setIsBtnPage ] = useState<boolean>(false);
  const [ isRowLimit, setIsRowLimit ] = useState<boolean>( false )


  const [ isBlockUser, setIsBlockUser ] = useState<boolean>(false);
  const [ isUnBlockUser, setIsUnBlockUser ] = useState<boolean>(false);


  // /*
  // */ Handle search 
  //   
  interface Filters {
    [key: string]: any;
  }

 // Function to handle search

  const [ searchValue, setSearchValue ] = useState('');
  const [ filters, setFilters ] = useState<Filters>({});
  
  const [ searchId, setSearchId ] = useState<string>('');
  const [ searchName, setSearchName ] = useState<string>('');
  const [ searchEmail, setSearchEmail ] = useState<string>('');
  const [ searchRole, setSearchRole ] = useState<string>(''); 
  const [ searchBlockedAt, setSearchBlockedAt ] = useState('');  

  const [ isNewFilter, setIsNewFilter ] = useState<boolean>( false );
  const [ isCleanInput, setIsCleanInput ] = useState<boolean>( false );

  // /*
  // */ Handle reports list filters
  // 

  // set filters to parameter for API
  const getFilters = () => {
    const additionalParams: any = {};
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        if ( key === 'id' && filters.id !== '' ) 
        {
          let id = filters.id.toString();
          setSearchId( id );
        } else if ( key === 'fullName[contains]' && filters['fullName[contains]'] !== '' ) 
        {
          let name = filters['fullName[contains]'];
          setSearchName( name );
        } else if ( key === 'role' && filters.role !== '' ) 
        {
          let roleSk = translationRoleSk( filters.role );
          setSearchRole( roleSk )
        } else if ( key === 'email[contains]' && filters['email[contains]'] !== '' ) 
        {
          let email = filters['email[contains]'];
          setSearchEmail( email )
        } else if ( key === 'blockedAt[isnull]' && filters['blockedAt[isnull]'] !== '') 
        {
          let blockedAt = filters['blockedAt[isnull]'];
          let blockedAtSk = translationBlockedAtSk( blockedAt )
          setSearchBlockedAt( blockedAtSk )
        }
        const filterValue = filters[key as keyof typeof filters];
        additionalParams[key] = filterValue;
      }
    }
    return additionalParams;
  };
  
  // remove data from filters
  const removeFilter = (filter: string) => {
    if (filters.hasOwnProperty(filter)) {
      const updatedFilters = { ...filters };
      delete updatedFilters[filter];
      setFilters(updatedFilters);
    }
  };

  // debounce if write to filter
  const handleInputBounce = ( value: any ) => {  
    handleEnterSearch()
  }

  // take data from child. searchId is as value in input
  const handleUserId = ( id: any ) => {
    setSearchId( id )
    handleSearchColumn( 'id', id )
    if ( id === '' ){
      removeFilter( 'id' )
    }
  }
  
  // take data from child. searchName is as value in input
  const handleUserName = ( name: string ) => {
    setSearchName( name )
    handleSearchColumn( 'fullName[contains]', name )
    if ( name === '' ){
      removeFilter( 'fullName[contains]' )
    }
  }

  // take data from child. searchEmail is as value in input
  const handleUserEmail = ( email: string ) => {
    setSearchEmail( email )
    handleSearchColumn( 'email[contains]', email )
    if ( email === '' ){
      removeFilter( 'email[contains]' )
    }
  }

  // handle data after push enter
  const handleSearchColumn = ( column: any, data: string | number ) => {
    // Update filters for new search
    setFilters(prevFilters => ({
      ...prevFilters,
      [column]: data,
    }));
  }
  
  // search after submit enter or debounce
  const handleEnterSearch = () => { 
    setPage( 0 );
    setNewInputValue( 1 );
    setOffset( 0 );
    setIsNewFilter((prevIsNewFilter) => !prevIsNewFilter);    
  }

  // if have search role, filter user role
  const handleUserRole = ( role: string ) => {

    if ( role === 'Zrušiť' ) {
      removeFilter( 'role' );
      setSearchRole('');
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        'role': translationRole(role),
      }));
      setSearchRole(role);
    }
    setPage( 0 );
    setNewInputValue( 1 );
    setOffset( 0 );
    setIsNewFilter( true ) ;   
  }

  const translationBlockedAt = ( blockedAt: string ) => {
    if ( blockedAt === 'Aktívny' ) {
      return true
    } else if ( blockedAt === 'Blokovaný' ) { 
      return false } else { return '' }
  }

  const translationBlockedAtSk = ( blockedAt: boolean ) => {
    if ( blockedAt === true ) {
      return 'Aktívny'
    } else if ( blockedAt === false ) { 
      return 'Blokovaný' } else { return '' }
  }

  // if have search blockedAt, filter user blockedAt
  const handleUserBlockedAt = ( blockedAt: string ) => {

    if ( blockedAt === 'Zrušiť' ) {
      removeFilter( 'blockedAt[isnull]' );
      setSearchBlockedAt('');
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        'blockedAt[isnull]': translationBlockedAt(blockedAt),
      }));
      setSearchBlockedAt(blockedAt);
    }
    setPage( 0 );
    setNewInputValue( 1 );
    setOffset( 0 );
    setIsNewFilter( true ) ;   
    
  }

  // getUsers if have something in filter
  useEffect( () => {
    if ( isNewFilter === true ) {
      setIsNewFilter( false )
      getUsers()
    }
  }, [ isNewFilter ]);

  // handle remove filter after click on input delete icon
  const handleClearInput = ( bool: boolean, children: string | number ) => {
    if ( bool === true ) { 
      if ( children === "Id" ) {
        removeFilter( 'id' );
        setSearchId('');
      } else if ( children === "Meno" ) {
        removeFilter( 'fullName[contains]' );
        setSearchName('');
      } else if ( children === "E-mail" ) {
        removeFilter( 'email[contains]' );
        setSearchEmail('');        
      }      
      // setIsCleanInput( true );
      setIsCleanInput((prevIsNewFilter) => !prevIsNewFilter);
      setPage( 0 );
      setNewInputValue( 1 );
      setOffset( 0 );
    }
  }

  // if click cross icon sort back acc id desc
  const handleClearInputs = ( bool: boolean ) => {
    if ( bool === true ) { 
      setSearchValue('');

      setSearchRole('');
      setSearchBlockedAt('');

      setSearchId('');
      setSearchName('');
      setSearchEmail('');

      setLastSortColumnType('id');
      setSortColumn('desc');
      setActiveColumn('');

      setFilters({});

      setPage( 0 );
      setNewInputValue( 1 )
      setOffset( 0 );

      // setIsCleanInput( true );
      setIsCleanInput((prevIsNewFilter) => !prevIsNewFilter);
    }
  }


  // getUsers without filters
  useEffect( () => {
    if ( isCleanInput === true ) {
      setIsCleanInput(false);
      getUsers()
    }
  }, [ isCleanInput, sortColumn, lastSortColumnType ]);



  // translate role from eng to sk
  const translationRoleSk = ( role: string ) => {
    if ( role === 'visitor' ) {
      return 'Návštevník';
    } else if ( role === 'specialist' ) {
      return 'Odborník';
    } else if ( role === 'admin' ) {
      return 'Administrátor';
    }
  }
  
  // translate role from sk to eng
  const translationRole = ( role: string ) => {
    if ( role === 'Návštevník' ) {
      return 'visitor';
    } else if ( role === 'Odborník' ) {
      return 'specialist';
    } else if ( role === 'Administrátor' ) {
      return 'admin';
    }
  }
    
  interface GetReportsParams {
    offset: number;
    limit: number;
    sort: string;
    [key: string]: string | number | boolean;
  }

  // /*
  // */ Handle users list 
  // 

  const translationColumn = ( column: string ) => {
    if ( column === 'Id' ){
      return 'id'
    } else if ( column === 'Meno' ) {
      return 'fullName'
    } else if ( column === 'Rola' ) {
      return 'role'
    } else if ( column === 'E-mail' ) {
      return 'email'
    } else if ( column === 'Stav' ) {
      return 'blockedAt'
    } else if ( column === 'Overené hlásenia' ) {
      return 'confirmedIncidents'
    } else if ( column === 'Všetky hlásenia' ) {
      return 'reportedIncidents'
    }
  }

  // Get users list from server
  const getUsers = async (     
    param: any = '', 
    searchQuery: string | number | boolean = '', 
    additionalParams?: any,) => {
    try {
      const params: GetReportsParams = {
        offset: offset,
        limit: rowLimit,
        sort: lastSortColumnType + ':' + sortColumn,
        ...getFilters()
      };

      if ( param && ![null, undefined].includes(searchQuery) ) {
        params[param] = searchQuery;
      }

      if (additionalParams) {
        // Merge the additionalParams object into the params object
        Object.assign(params, additionalParams);
      }

      await apiClient.get('/v1/users',{
        params: params,      
        
      }
      ).then( response => {
        let query: Query[] = response.data.items;
        let store: Incidents[] = query.map(({ stats, ...rest }) => ({
          ...rest,
          reportedIncidents: stats.reportedIncidents,
          confirmedIncidents: stats.confirmedIncidents,
        }));
        let storeUser: any = store.map( (user: any) => {
          if ( user.role === 'visitor' ) {
            return { ...user, role: 'Návštevník' };
          } else if ( user.role === 'specialist' ) {
            return { ...user, role: 'Odborník' };
          } else if ( user.role === 'admin' ) {
            return { ...user, role: 'Administrátor' };
          } else {
            return user
          }
        });

        let userActiveStore: any = storeUser.map( (user: any) => {
          if ( user.blockedAt === null ) {
            return { ...user, blockedAt: 'Aktívny' }
          } else { return { ...user, blockedAt: 'Blokovaný' } }
        })

        let total = (response.data.totalCount) ;
        let numOfPages = Math.ceil(total / rowLimit);

        setUsers( userActiveStore );
        settotalUsers( total );
        setNumberOfPages( numOfPages );
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }

  // get users after open page 
  useEffect( () => {
    if ( returningFromProfil === false && ( !location.state?.filter ) )  {
      getUsers();
    }

    if (isReturningFromEdit || isReturningLimitRow) {
      setIsReturningFromEdit(false);
      setIsReturningLimitRow(false);
    }
  }, [isReturningFromEdit, isReturningLimitRow, returningFromProfil]);

  // get data from user profile
  useEffect(() => {
    if ( location.state !== null && location.state.filter ) {
      const filter = location.state.filter;
      setFilters(filter);
      const { filter: removedFilter, ...remainingState } = location.state;
      navigate("/pages/user/users-list", { state: remainingState });
      setReturningFromProfil( true )
    }
  }, []);

  // get data if coming back from user profile
  useEffect(() => {
    if ( returningFromProfil === true ) {
      getUsers();  
    }
  }, [ returningFromProfil ]);

  // refresh if get data from edit page
  useEffect( () => {
    if (location.state && location.state.refresh) {
      setIsReturningFromEdit(true);
    }
  }, [location.state]);


  // send data user to edit page
  const handleEditUser = ( data: any ) => {
    let id = data.id;
    let currRowLimit = rowLimit;
    navigate( (`/pages/user/editUser/${data.id}`), { state: {
      id: id,
      currRowLimit: currRowLimit,      
    }} );    
  }

  // on click btn pridat pouzivatela go to page addUser
  const addNewUser = () => {
    navigate( ('/pages/user/addUser') ); 
  }

  // send data user to profile page
  const handleProfileUser = ( data: any ) => {
    const id = data.id;
    const filter = filters
    navigate( (`/pages/user/userProfile/${id}`), { state: { id, filter } } );
  }
  

  // /*
  // */ Handle block/unblock user 
  // 

  // if click on zrusit btn close window
  const handleCloseWindow = ( bool: boolean ) => {
    if (bool === true) setIsBlockUser(false) 
    if (bool === true) setIsUnBlockUser(false) 
  }

  // handle icon block user
  const handleBtnBlockUser = ( bool: boolean ) => {
    if ( bool === true)
      blockUser( user.id )
      setIsBlockUser(false);
  }

  // Handle btn block user at window
  const handleBlockUser = ( data: any ) => {
    setIsBlockUser(true);
    setUser( data );
  }

  // block user
  const blockUser = async ( id: number ) => {
    try {
      await apiClient.put(`/v1/users/${id}/block`
      ).then( ( response: any ) => {
        getUsers()            
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }
 
  // handle btn unblock user at window
  const handleBtnUnBlockUser = ( bool: boolean ) => {
    if ( bool === true)
      unBlockUser( user.id )
      setIsUnBlockUser(false);
  }

  // Handle icon unblock user at window
  const handleUnBlockUser = ( data: any ) => {
    setIsUnBlockUser(true); 
    setUser( data );   
  }

  // unblock user
  const unBlockUser = async ( id: number ) => {
    try {
      await apiClient.delete(`/v1/users/${id}/block`
      ).then( () => {
        getUsers()
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }
  

  // /*
  // */ Handle setting page 
  // 

  const [ isCombineSort, setIsCombineSort ] = useState<boolean>(false)

  // set sort with filter
  const handleCombineSort = ( 
    dataSearch: string | number,
    columnName: string, 
    columnSort: string, 
    sort: string, ) => {

    setLastSortColumnType( translationColumn(columnSort) );
    setSortColumn( sort )
    setIsCombineSort( true )    
  }

  // if have filter and sort getUsers
  useEffect( () => {
    if ( isCombineSort === true ) {
      setIsCombineSort(false);
      getUsers()
    }
  }, [ isCombineSort, sortColumn, lastSortColumnType ]);
  
  // Handle column sort
  const handleSortColumn = ( children: string, ascDesc: string ) => {
    if( children === 'Id' ){
      setLastSortColumnType( 'id' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Meno' ){
      setLastSortColumnType( 'fullName' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Rola' ){
      setLastSortColumnType( 'role' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'E-mail' ){
      setLastSortColumnType( 'email' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Stav' ){
      setLastSortColumnType( 'blockedAt' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Overené hlásenia' ){
      setLastSortColumnType( 'confirmedIncidents' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    } else if( children === 'Všetky hlásenia' ){
      setLastSortColumnType( 'reportedIncidents' );
      setSortColumn( ascDesc )
      setIsSortColumn( true )
    }
  }

  // refresh if sort data
  useEffect( () => {
  if ( isSortColumn ) {
    setIsSortColumn( false );
    getUsers()
    }
  }, [ isSortColumn ]);


  // ////// Handle if change row limit
  // 

  // take row limit from child
  const handleRowLimit = (num: number) => {
    let lastPage = Math.ceil(( totalUsers / num ) - 1);

    setRowLimit(num)
    setIsRowLimit( true )

    setPage( 0 )
    setOffset( 0 ) 
    setNewInputValue( 1 )
  }

  // refresh if change row limit
  useEffect( () => {
    if ( isRowLimit === true ) {      
      getUsers()
      setIsRowLimit( false )
      setActualPage()     
    }
  }, [isRowLimit]);

  // Handle actual page
  const setActualPage = () => {
    let actualPage =Math.ceil( offset / rowLimit );
    setPage( actualPage )
  }

  // after click on btn arrow next set offset
  const handleNextPage = ( boolValue: boolean ) => {
    if ( boolValue === true ){
      let value = (+offset) + (+rowLimit)
      let actualPage = +page + 1 ;
      setOffset( value );
      setIsNextPage( true );
      setNewInputValue((+actualPage + 1));   
    }
  }

  // refresh if click on arrow next
  useEffect( () => {
    if ( isNextPage === true ) {
      setIsNextPage(false);       
      if ( filters ){
        getUsers()
        setActualPage()
      }
    }
  }, [offset, page, filters]);


  // after click on btn arrow previous set offset
  const handlePreviousPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset > 0 ){
      let offsetValue = +offset - rowLimit
      let actualPage = +page - 1 ;
      setIsPreviousPage( true );  
      setOffset( offsetValue );
      setNewInputValue((+actualPage + 1));
    }
  }  

  // refresh if click on arrow previous
  useEffect( () => {
    if ( isPreviousPage === true ) {
      setIsPreviousPage(false);       
      if ( filters ){
        getUsers()
        setActualPage()
      }
    }
  }, [offset, page, filters]);
 
  // after click on btn number set offset
  const handleBtnPage = ( btnPage: number ) => {
      let offsetValue = btnPage * rowLimit;
      setOffset( offsetValue );
      setIsBtnPage( true );         
  }

  // refresh if click on btn number
  useEffect( () => {
    if ( isBtnPage === true ) {
      setIsBtnPage(false);       
      if ( filters ){
        getUsers()
        setActualPage()
      }
    }
  }, [offset, page, filters]);


  /////////////////// handle page if length pages > 6

  const [ newInputValue, setNewInputValue ] = useState( page + 1)
  const [ isEnter, setIsEnter ] = useState<boolean>(false)
  

  // take from child new number in input
  const handleInputValue = ( num: number ) => {
    setNewInputValue( num )
  }

  // if push enter, render entries acc input number
  const handleEnter = ( bool: boolean ) => {
    if ( bool === true ) {
      let newPage = +newInputValue - 1 ;
      let newOffset = +newPage * rowLimit ;
      setPage( newPage );
      setOffset( newOffset );
      setIsEnter(true);
    }
  } 

  useEffect( () => {
    if ( isEnter === true ) {
      setIsEnter( false );
      if ( filters ){
        getUsers()
        setActualPage()
      }
    }
  }, [isEnter, offset, page, filters]);


  // Handle active sorting arrows
  const [activeColumn, setActiveColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('');

  const handleAscDesc = (column:string, sort:string) => {
    setActiveColumn(column)
    setSortDirection(sort);
  };


  // const queryParams = new URLSearchParams(location.search);

  // // Update filter options and navigate
  // const applyFiltersAndNavigate = () => {
  //   const searchParams = new URLSearchParams(filterOptions);
  //   navigate(`/pages/user/users-list?${searchParams.toString()}`);
  // };


  return (

    <MDBox 
    sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
    display: "flex", flexDirection: "column", justifyContent: "center", 
    marginTop: "1rem", marginBottom: "1rem", 
    }}
    >

      <Grid display="flex" justifyContent="flex-end" 
        sx={{ pb: "1rem" }}
      >
        <MDButton 
          variant="gradient" 
          color="success"
          onClick={addNewUser}
          width={4} xs={2}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;Pridať používateľa
        </MDButton>                    
      </Grid>      
        
      <Card>
        <MDBox sx={{ display:"flex", flexDirection: "row", padding: "1.7rem 1.5rem 0.7rem 1.7rem"}} >  
          <MDBox lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Zoznam používateľov
            </MDTypography>            
          </MDBox>     
        </MDBox>

        <DataTable 
          onProfileUser={ handleProfileUser }
          onEditUser={ handleEditUser }
          onBlockUser={ handleBlockUser }
          onUnBlockUser={ handleUnBlockUser }
          onRowLimit={ handleRowLimit }
          rowLimit={ rowLimit }
          tableColumns={ dataTableHeader.columns } 
          tableRows={ users ? users : [] }
          totalUsers={ totalUsers }
          actualPage={ page }
          numberOfPages={ numberOfPages }
          onNextPage={ handleNextPage }
          onPreviousPage={ handlePreviousPage }
          onBtnPage={ handleBtnPage }
          onSortColumn={ handleSortColumn }
          onInputValue={ handleInputValue }
          newInputValue={ newInputValue }
          onEnter={ handleEnter }

          onEnterSearch={ handleEnterSearch }
          onUserId={ handleUserId }
          onUserName={ handleUserName }
          onUserEmail={ handleUserEmail }
          onUserRole={ handleUserRole }
          onUserBlockedAt={ handleUserBlockedAt }

          onClearInput={ handleClearInput }
          onClearInputs={ handleClearInputs }
          searchRole={ searchRole }

          searchId={ searchId }
          searchName={ searchName }
          searchEmail={ searchEmail }
          searchBlockedAt={ searchBlockedAt }

          searchValue={ searchValue }

          onCombineSort={ handleCombineSort }

          activeColumn={activeColumn}
          sortDirection={sortDirection}
          onAscDesc={handleAscDesc}

          onInputBounce={ handleInputBounce }
          />

          { (isBlockUser === true && user.blockedAt === "Aktívny") ? 
          <BlockUser
            onCloseWindow={handleCloseWindow}
            onBtnBlockUser={handleBtnBlockUser}
          /> : ''}

          { (isUnBlockUser === true && user.blockedAt === "Blokovaný") ?
          <UnBlockUser
          onCloseWindow={handleCloseWindow}
          onBtnUnBlockUser={handleBtnUnBlockUser}
        /> : '' }
        
      </Card>

    </MDBox>
  );
}

export default UsersList;
