import React from 'react'

// @material-ui core components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


type Props = {
  user: any,
  onCloseDeleteWindow: (bool: boolean) => void,
  onSubmitDelete: (id: number) => void,
}

const DeleteUser = ( { user, onCloseDeleteWindow, onSubmitDelete }: Props) => {

  const handleDelete = () => {
    
    onSubmitDelete( user.id )
  }

  const closeWindow = () => {
    onCloseDeleteWindow( true );
  }

  return (
    <Card 
        sx={ { 
          width: 1/3,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // backgroundColor: "#5ada55",
        } }
    >
          <MDBox 
            className='delete'
            sx={ {
              display: "flex",
              flexDirection: "column" ,
              justifyContent: "center" ,
              alignItems: "center" ,
              marginLeft: "0",
              // flexWrap: "wrap",
            } }
            >
            <MDBox p={3}>
              <MDTypography variant="h5">Naozaj chcete odstrániť používateľa?</MDTypography>
            </MDBox>
          
            <MDBox 
              sx={ { 
                display:"flex",
                paddingBottom:"1rem", 
                gap:"0.8rem"
                
              } } 
            >
              <MDButton 
                variant="gradient" 
                color="light" 
                size="small"
                onClick={closeWindow}
                >
                Zrušiť
              </MDButton>

              <MDButton 
                variant="gradient" 
                color="error" 
                size="small"
                onClick={handleDelete}
                >
                Odstrániť
              </MDButton>
            </MDBox>
            
          </MDBox>          
        </Card>
  )
}

export default DeleteUser