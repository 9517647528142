
// import { DateRangePicker } from "materialui-daterange-picker";
import { DateRangePicker } from "components/DateRangePicker";

import ClearIcon from '@mui/icons-material/Clear';

import Grid from '@mui/material/Grid';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import 'css/datePicker.css'

type Props = {
  column?: any;
  isDateWindow: boolean;

  onDateRange?: ( date: any, column: any ) => void;
  onSubmitDate?: ( bool: boolean, column: string ) => void;
  onCloseDateRange: ( bool: boolean ) => void;
};

const Timeline: React.FunctionComponent<Props> = ({ 
  column, 
  isDateWindow,

  onDateRange,
  onSubmitDate,
  onCloseDateRange,
  }: Props) => {

  const toggle = () => onCloseDateRange(false);

  const handleDateSubmit = () => {
    onSubmitDate( true, column )    
  };

  const handleOverlayClick = ( e: React.MouseEvent<HTMLDivElement> ) => {
    // Prevent clicks on the overlay from propagating to the DateRangePicker
    // e.stopPropagation();
  };


  const handleInputDateRange = ( OccuredAt: any ) => {
    onDateRange( OccuredAt, column )
  };

  const handleTitle = () => {
    if ( column === 'Dátum udalosti' ) {
      return 'Dátum udalosti';
    } else if ( column === 'Nahlásené' ) {
      return 'Dátum nahlásenia'
    } else { 
      return 'Zvoľte obdobie za ktoré chcete exportovať hlásenia' }
  }

  return (
         
    <Grid
      className="containerPicker"
      onClick={() => handleOverlayClick}
      style={{
        position: "fixed",
        top: "10%",
        left: "30%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        width: "50rem",
        height: "34.5rem",
        borderRadius: "0.75rem",
        zIndex: "5",
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      }}
    >
      <MDBox
      sx={{
        display: "flex", flexDirection: "row", width: "100%"
      }}
      >
        <MDTypography
          sx={{
            display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "80%",
            marginTop: "2rem", marginBottom: "1rem", paddingLeft: "1.5rem", 
          }}
        >
          { handleTitle() }
        </MDTypography>

        <MDBox
          sx={{
            display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "20%",
            marginTop: "1.5rem", marginBottom: "1rem", paddingRight: "1.5rem", cursor: "pointer"
          }}
        >
          <ClearIcon />
        </MDBox>
      </MDBox>      
        
        <DateRangePicker
          open={isDateWindow}
          toggle={toggle}
          // maxDate={new Date()}
          onChange={(newDate: any) => handleInputDateRange(newDate)}  
        />
      
      {/* {dateRange.startDate && dateRange.endDate && ( */}           
        <MDButton 
            variant="gradient" 
            color="light" 
            size="small"
            onClick={handleDateSubmit}                
            sx={{ 
              marginTop: "-1rem", marginBottom: "2rem", cursor: "pointer",
              minHeight: "2.5rem", minWidth: "64px", zIndex: "5"
            }}
            >
            Exportovať hlásenia
          </MDButton>

    </Grid>
  );
};

export default Timeline;