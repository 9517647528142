/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useRef } from 'react'

import { useNavigate } from "react-router-dom";

import DOMPurify from "dompurify";

// types
import { UserEdit } from 'types/interface'

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MapComponent from 'components/MapComponent';

// Settings page components
import FormField from "layouts/pages/user/components/FormField";

import selectData from "layouts/pages/user/editUser/components/BasicInfo/data/selectData";

interface Props {
  user?:UserEdit,
  name: string,
  role: string,
  onEditUserName: (name: string) => void,
  onEditUserRole: (role: string) => void,
  onSubmitEdit: ( verify: string ) => void,
}

function BasicInfo({ 
  user, 
  name,
  role,
  onEditUserName, 
  onEditUserRole, 
  onSubmitEdit, 

}: Props): JSX.Element {

  const navigate = useNavigate();

  const nameInput = useRef<HTMLInputElement>(null)
  const roleInput = useRef<HTMLInputElement>(null)

  // push edit data to API and go back to table page
  const submitEdit = ( e: any ) => {
    e.preventDefault();

    onSubmitEdit( 'true' )
  }

  // get data from name input
  const getInputName = () => {
    let userName = DOMPurify.sanitize( nameInput.current?.value );
    onEditUserName(userName);
  }

  
  // get data from role input
  const changeRoleInput = ( e:any, newValue: any ) => {
    onEditUserRole(newValue);
  }
  
  const mapCenter = {
    lat: user.locationLatitude, 
    lng: user.locationLongitude, 
  };

  const colorNotif = user.notificationsIsEnabled === 'Zapnuté';
  const isVisible = name || role;

  return (
    
    <Card sx={{ width: "47rem", }} >
      <MDBox
        sx={{
          display:"flex", flexFlow: "row wrap", padding: "1.5rem",
        }}
      >
        <MDBox sx= {{ flex: "1 100%", paddingBottom: "1rem" }} >
          <MDTypography variant="h5">Osobné Informácie</MDTypography>
          <MDTypography variant="button" >
            Id používateľa:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{user.id ? user.id : ''}
          </MDTypography>
        </MDBox>
        
        <Grid 
          sx={{ display: "flex", flexDirection:"row ", 
          width:"100%", gap: "1.5rem",
        }}>

          <Grid sx={{ width:"50%", }} >

            <Grid item >
              <MDTypography variant="button" >
                Meno
              </MDTypography>
              <FormField 
                sx={{ marginTop: "-0.5rem", }} 
                placeholder={user.fullName ? user.fullName : ''}
                inputRef={nameInput}
                onChange={getInputName}
              />
            </Grid>

            <Grid sx={{ mt: "1.6rem" }} >
              <MDTypography 
                variant="button" >
                Rola
              </MDTypography>                                 
              <Autocomplete           
                  options={selectData.roleUser}
                  onChange={changeRoleInput}
                  renderInput={(params) => (
                    <FormField {...params}
                      sx={{ marginTop: "-0.5rem", }}                      
                      InputLabelProps={{ shrink: true }}
                      placeholder={user.role ? user.role : ''}
                      inputRef={roleInput}                      
                    />
                  )}
              />
            </Grid>
            
            <Grid 
              sx={{ 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              }} 
            >             
              <MDTypography sx={{ mt: "1.2rem",}} 
                variant="button"                   
              >
                Email
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "1.2rem",} }
              fontWeight="regular" 
              color="text">
                {user.email ? user.email : ''}
              </MDTypography>
            
            </Grid>    

          </Grid>

                  
          <Grid sx={{ width: "50%" }} >

            <MDBox 
              sx={{ pb: "1rem", display: "flex", flexDirection: "row",
              alignItems: "center", justifyContent: "space-between",
              
            }} >
              <MDTypography variant="button" >
                Hlásenia o výskyte
              </MDTypography> 
              <MDTypography variant="caption" fontWeight="regular"
                sx={{ paddingRight: "1rem",
                  color: colorNotif ? "#4CAF50" : "#F44334",
                }}
              >
                { user.notificationsIsEnabled ? user.notificationsIsEnabled : '' }
              </MDTypography>                
            </MDBox>

            {user.notificationsIsEnabled === 'Zapnuté' && ( 
            <MDBox>
              <MDBox 
                sx={{ display: "flex", flexDirection: "column",
                      alignItems: " flex-start", paddingBottom: "1.5rem",
                      paddingTop: "1rem" }}>
                <MDTypography variant="button" >
                  Lokalita hlásení
                </MDTypography>
                <MDTypography variant="button" 
                  sx={ { marginTop: "1.3rem",} }
                  fontWeight="regular" 
                  color="text">
                  {user.location ? user.location : ''}
                </MDTypography>                    
              </MDBox>
              
              <MapComponent center={mapCenter} />

                <MDBox 
                sx={{ display: "flex", flexDirection: "column",
                      alignItems: " flex-start", paddingTop: "1.5rem" }}>
                <MDTypography variant="button" >
                  Rádius
                </MDTypography>
                <MDTypography variant="button" 
                  sx={ { marginTop: "1.3rem",} }
                  fontWeight="regular" 
                  color="text">
                  {user.radius ? user.radius : '-'}&nbsp;km
                </MDTypography>                    
              </MDBox>   

            </MDBox>
            )}                  
                      
          </Grid> 
        </Grid>         

      </MDBox>
                       
      <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", 
                  gap: "1rem", paddingBottom: "1rem", paddingRight: "1rem" }}>

        <Grid>
          <MDButton 
            variant="outlined" 
            color="success" 
            sx={{ height: "50%", }}
            onClick={() => navigate(-1)}
          >
            Naspäť
          </MDButton>                    
        </Grid>

        <Grid>
          <MDButton 
            variant="gradient" 
            color="success" 
            sx={{ height: "50%",
            visibility: isVisible ? "visible" : "hidden",
            }}
            onClick={submitEdit}
          >
            Uložiť
          </MDButton>                  
        </Grid>
        
      </Grid>

    </Card>    
  );
}

export default BasicInfo;
