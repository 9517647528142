/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MapComponent from 'components/MapComponent';


// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// types
import { ReportInfo } from 'types/interface'

interface Props {
  report?: ReportInfo,
}

function ReportProperties( { 
  report,
  } :Props ): JSX.Element {

  const mapCenter = {
    lat: report.locationLatitude, 
    lng: report.locationLongitude, 
  };
      
  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox        
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >    

        <MDBox p={1} pb={0}
          sx= {{ flex: "1 100%", }} > 

          {/* Header */}
          <MDBox 
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <MDTypography variant="h5">Vlastnosti hlásenia</MDTypography>

          </MDBox>

          <MDTypography variant="button" 
            sx={{ fontWeight: "500",
                  fontSize:"0.7rem" }}>
            ID HLÁSENIA:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{report.id}
          </MDTypography>
        </MDBox>

        <Grid container pb={3} px={1} pt={0} mt={0}
          sx={{ width:"100%", }} >
          
          {/* typ nalezu */}
          <Grid 
            container
            xs={12} sm={12} p={0} mt={0}>            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  fontWeight: "500",
                  fontSize:"0.7rem",                     
                } } 
                variant="button"                   
                >
                TYP
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                {report.type}
              </MDTypography>
            </MDBox>
          </Grid>   

          {/* Stav (čaká / schválené / zamietnuté) */}              
          <Grid 
            container
            xs={12} sm={12} >            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                STAV
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
              { report.status === 'Schválené' ? (
                <span style={{ color: "#4CAF50", fontSize: "1.2rem" }}>
                  &#x25cf; 
                </span>
              ) : report.status === 'Zamietnuté' ? (
                <span style={{ color: "#F44334", fontSize: "1.2rem" }}>
                  &#x25cf; 
                </span>
              ) : report.status === 'Čaká na schválenie' ? (
                <span style={{ color: "#FB8C00", fontSize: "1.2rem" }}>
                  &#x25cf; 
                </span>
              ) : null }
                &nbsp;{ report.status }
              </MDTypography>
            </MDBox>
          </Grid>      

          {/* nahlasovatel */}
          <Grid 
            container
            xs={12} sm={12} >            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                NAHLASOVATEĽ
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem",} }
              fontWeight="regular" 
              color="text">
                { report.fullName }
              </MDTypography>
            </MDBox>
          </Grid>  

          {/* lokalita*/}
          <Grid 
            container
            xs={12} sm={12} >            
            <MDBox 
              sx={ { 
                display: "flex",
                flexDirection: "column",
                mt: "1.2rem",
                lineHeight: "1.625",
                letterSpacing: "0.00938em",
              } } 
            >
              <MDTypography 
                sx={ {                         
                  // mt: "1.2rem", 
                  fontWeight: "500",
                  fontSize:"0.7rem",                       
                } } 
                variant="button"                   
                >
                LOKALITA
              </MDTypography>              
              <MDTypography variant="button" 
              sx={ { marginTop: "0.2rem", marginBottom: "0.5rem",} }
              fontWeight="regular" 
              color="text">
                { report.location }
              </MDTypography>
            </MDBox>

            <MapComponent center={mapCenter} />

          </Grid>

        </Grid>
        
      </MDBox>
    </Card>
  );
}

export default ReportProperties;
