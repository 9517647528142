import axios from "axios";
import { getAPIUrl } from "config";

export const apiClient = axios.create({
  baseURL: getAPIUrl(),
  // baseURL: 'https://api.medvede.cultify.studio',
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token');

  if (token)
    config.headers['Authorization'] = 'Bearer ' + token;

  return config;
});


