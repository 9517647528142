
import { ReactNode, useRef } from "react";

import DOMPurify from "dompurify";

// @mui material components
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

import Autocomplete from '@mui/material/Autocomplete';

import { debounce } from 'lodash'

import 'css/dataTable.css'

// Declaring props types for DataTableHeadCell
interface Props {
  width?: string | number;
  children: ReactNode;
  sorted?: false | "none" | "asce" | "desc";
  align?: "left" | "right" | "center";
  onEnterSearch?: ( bool: boolean, children: any ) => void;
  onSortColumn?: ( child: any, ascDesc: string ) => void;
  onUserId?: ( id: string ) => void;
  onUserName?: ( role: string ) => void;
  onUserEmail?: ( email: string) => void;
  onUserRole?: ( role: string ) => void;
  onUserBlockedAt?: ( role: string ) => void;
  onClearInput?: ( bool: boolean, column: string | number ) => void;
  onClearInputs?: ( bool: boolean ) => void;
  
  searchId?: string;
  searchName?: string;
  searchRole?: string;
  searchEmail?: string;
  searchBlockedAt?: string;
  
  searchValue?: string;

  onCombineSort?: ( dataSearch: string | number, columnName: string, columnSort: string, sort: string ) => void;

  activeColumn:string; 
  sortDirection:string; 
  onAscDesc:( column:string, sort:string ) => void;

  onInputBounce: (bool: any) => void;
}

function DataTableHeadCell({ 
  width, 
  children, 
  sorted, 
  align, 
  onEnterSearch,
  onSortColumn,
  onUserId, 
  onUserName,
  onUserEmail,
  onUserRole,
  onUserBlockedAt,
  onClearInput,
  onClearInputs,
  
  searchValue,
  
  searchBlockedAt,
  searchId,
  searchName,
  searchRole,
  searchEmail,

  onCombineSort,

  activeColumn, 
  sortDirection, 
  onAscDesc,
  onInputBounce,
  ...rest }: Props): JSX.Element {
        

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const blockedAt = [ 'Aktívny', 'Blokovaný' ];
  const role = [ 'Administrátor', 'Odborník', 'Návštevník' ];
  
  const nameInput = useRef<HTMLInputElement>(null)
  const idInput = useRef<HTMLInputElement>(null)
  const emailInput = useRef<HTMLInputElement | null>(null)
  const roleInput = useRef<HTMLInputElement>(null)
  const blockedAtInput = useRef<HTMLInputElement>(null)


  // input element
  const debouncedHandleInput = debounce(( value: any ) => {
    onInputBounce('true');
  }, 1000);


  // get data from id input
  const handleInputId = () => {
    let searchString = DOMPurify.sanitize( idInput.current?.value );
    onUserId( searchString );  
  };

  // get data from name input
  const handleInputName = () => {
    let searchString = DOMPurify.sanitize( nameInput.current?.value );
    onUserName(searchString); 
  };

  // get data from email input
  const handleInputEmail = () => {
    let searchString = DOMPurify.sanitize(  emailInput.current?.value);
    onUserEmail( searchString ); 
  };

  // get data from role input
  const getInputRole = ( e:any, newValue: any ) => {
    if ( newValue.value === 'clear' ) {
      onUserRole( 'Zrušiť' )
    } else { 
      onUserRole(newValue)
    }
  }

   // get data from role input
   const getInputBlockedAt = ( e:any, newValue: any ) => {
    if ( newValue.value === 'clear' ) {
      onUserBlockedAt( 'Zrušiť' ) 
    } else { onUserBlockedAt(newValue) }    
  }

  // send data to parent after push enter
  const handleSearchEnter = ( e: any, children: any ) => {
    if (e.key === 'Enter') {
      onEnterSearch( true, children )
    }
  }
  
  // handle sort column
  const handleAscDesc = ( column: string, sort: 'asc' | 'desc' ) => {
    // onSortColumn( column, ascDesc )
    onAscDesc( column, sort )
  
    if ( searchRole ) {
      onCombineSort( searchRole, 'role', column, sort )
    } else if ( searchId ) {
      onCombineSort( searchId, 'id', column, sort )
    } else if ( searchName ) {
      onCombineSort( searchName, 'fullName', column, sort )
    } else if ( searchEmail ) {
      onCombineSort( searchEmail, 'email', column, sort )
    } else if ( searchBlockedAt ) {
      onCombineSort( searchBlockedAt, 'blockedAt[isnull]', column, sort )
    } else {
      onSortColumn( column, sort )
    }
  }

  const handleClear = () => {
    onClearInputs(true);    
  }

  const handleClearFilter = ( column: any ) => {
    onClearInput( true, column );
  }

  return (

    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={1}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >

  { children === 'Stav' ? (
    <MDBox
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
      })}
    >
      <Autocomplete
        options={[...blockedAt, { label: 'Zrušiť výber', value: 'clear' }]}
        value={searchBlockedAt}
        inputValue={searchBlockedAt}
        onChange={getInputBlockedAt}  
        disableClearable      
        ListboxProps= {{
          sx: {
            "& div": {  minWidth: "25rem !important", width: "20rem"  },
            "& li": {  marginLeft: "-0.75rem", marginRight: "0rem", fontSize: "0.85rem !important", borderRadius: "none", },
            "& li:last-child": { color: "#000", backgroundColor: "#FF7866", borderRadius: "0px", fontWeight: "400"},
          }
        }}
        renderInput={(params) => (
        <MDInput
          {...params}
          right={align !== 'right' ? '16px' : 0}
          left={align === 'right' ? '-5px' : 'unset'}
          sx={{
            width: "6.9rem",
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              marginRight: 0, 
              right: "5px",
            },
            '& button': {
              paddingRight: "0rem",
              paddingLeft: "0rem",
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingRight: '0', 
              paddingLeft: '0.3rem', 
              fontSize: '0.75rem', 
            },
          }}
          size="small"
          label={children}
          variant="outlined"
          inputRef={blockedAtInput}
          // placeholder="Stav"
          // endAdornment={
          //   searchBlockedAt ? (
          //     <ClearIcon
          //       sx={{
          //         position: 'absolute',
          //         right: 8,
          //         top: '50%',
          //         transform: 'translateY(-50%)',
          //         cursor: 'pointer',
          //       }}
          //       onClick={handleClear}
          //     />
          //   ) : null
          // }
        />
        )}
      />
      <MDBox
      // className={`${ activeColumn === 'Stav' ? 'active' : ''}`} 
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9'
        }}
      >
        <MDBox
          // className={`${ (sortDirection === 'asc' && activeColumn === children) ? 'activeStav' : ''}`}
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'asc') }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
        </MDBox>
        <MDBox
          // className={`${ (sortDirection === 'desc' && activeColumn === children) ? 'activeStav' : ''}`}
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </MDBox>
      </MDBox>
    </MDBox>

  ) : children === 'Rola' ? (
    <MDBox      
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
      })}
    >
      <Autocomplete
        options={[...role, { label: 'Zrušiť výber', value: 'clear' }]}
        value={searchRole}
        inputValue={searchRole}
        onChange={getInputRole} 
        disableClearable 
        ListboxProps= {{
          sx: {
            "& div": {  minWidth: "25rem !important", width: "20rem"  },
            "& li": {  marginLeft: "-0.75rem", marginRight: "0rem", fontSize: "0.85rem !important", borderRadius: "none", },
            "& li:last-child": { color: "#000", backgroundColor: "#FF7866", borderRadius: "0px", fontWeight: "400"},
          }
        }}
        // getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        // PopperComponent={CustomPopper}

        // componentsProps={{
        //   clearIndicator: {
        //     onClick: handleClear,
        //   },
        // }}
        renderInput={(params) => (
        <MDInput
          {...params}
          right={align !== 'right' ? '16px' : 0}
          left={align === 'right' ? '-5px' : 'unset'}
          sx={{
            width: "7.8rem",
            '& .MuiAutocomplete-inputRoot': {
              // width: "max-content",
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              marginRight: 0, 
              right: "5px",
            },
            '& button': {
              paddingRight: "0rem",
              paddingLeft: "0rem",
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingRight: '0', 
              paddingLeft: '0.3rem', 
              fontSize: '0.75rem', 
            },
          }}
          size="small"
          label={children}
          variant="outlined"
          inputRef={roleInput}
          placeholder="Rola"
          
          //   searchRole ? (
          //     <ClearIcon
          //       sx={{
          //         position: 'absolute',
          //         right: 8,
          //         top: '50%',
          //         transform: 'translateY(-50%)',
          //         cursor: 'pointer',
          //       }}
          //       onClick={handleClear}
          //     />
          //   ) : null
          
        />
        )}
      />

      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9',
        }}
      >
        <MDBox       
          // className={`${sortDirection === 'asc' && activeColumn === children ? 'activeRola' : ''}`} 
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'asc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
          {/* <Icon>arrow_drop_up</Icon> */}
        </MDBox>
        <MDBox
          // className={`${sortDirection === 'desc' && activeColumn === children ? 'activeRola' : ''}`}
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
          {/* <Icon>arrow_drop_down</Icon> */}
        </MDBox>

      </MDBox>
    </MDBox>
  ) : children === 'Id' ? (
    <MDBox      
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,         
      })}      
    >
          <MDInput            
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }: any) => ({
              fontSize: size.lg,
              width: "100%",
              paddingRight: "0rem",
              '& .css-qnqh4t-MuiInputBase-input-MuiOutlinedInput-input': {
                  paddingRight: "0rem", 
                  // width: "90%"                  
                },
              '& .css-fnfwl6-MuiInputBase-root-MuiOutlinedInput-root': {
                  paddingRight: "0rem", 
                  // width: "90%"                  
                },
              })
            }
            size="small"
            label={children}
            variant="outlined"
            inputRef={ idInput }
            value={ searchId }
            // value={ searchId === null ? '' : searchId.toString() }
            // inputValue={ searchId === null ? '' : searchId.toString() }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputId(); 
              debouncedHandleInput(idInput.current.value);
            }}
            onKeyDown={ ( e:any ) => handleSearchEnter( e, children )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"
                  sx={{ paddingLeft: "-2rem", paddingRight: "0rem", scale: "0.7"}}
                >
                  { searchId &&( 
                    <IconButton 
                      sx={{ paddingLeft: "0rem", paddingRight: "0.8rem" }}
                    edge="end" onClick={() => handleClearFilter( children )}>
                      <Icon>clear_icon_component</Icon>
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0rem",
              lineHeight: "0rem",
              scale: '0.9',
            }}
          >
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'asc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'asc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'asc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_up</Icon> */}
            </MDBox>
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'desc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'desc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'desc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_down</Icon> */}
            </MDBox>
          </MDBox>
        </MDBox>
  ) : children === 'Meno' ? (
        <MDBox
          display="flex" flexDirection="row"
          right={align !== "right" ? "16px" : 0}
          left={align === "right" ? "-5px" : "unset"}
          sx={({ typography: { size } }: any) => ({
            fontSize: size.lg,
          })}
        >
          <MDInput            
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }: any) => ({
              fontSize: size.lg,
            })}
            size="small"
            label={children}
            variant="outlined"
            inputRef={ nameInput }
            // inputValue={ searchName }
            value={ searchName }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputName(); 
              debouncedHandleInput(nameInput.current.value);
            }}
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputName( e.target.value )}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // handleKeyDown(e);
              handleSearchEnter(e, children);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"
                  sx={{ paddingLeft: "-2rem", paddingRight: "0rem", scale: "0.7"}}
                >
                  { searchName && ( 
                    <IconButton 
                      sx={{ paddingLeft: "0rem", paddingRight: "0.8rem" }}
                    edge="end" onClick={() => handleClearFilter( children )}>
                      <Icon>clear_icon_component</Icon>
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          >
          </MDInput>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0rem",
              lineHeight: "0rem",
              scale: '0.9',
            }}
          >
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'asc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'asc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'asc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_up</Icon> */}
            </MDBox>
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'desc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'desc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'desc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_down</Icon> */}
            </MDBox>
          </MDBox>
        </MDBox>
  ) : children === 'E-mail' ? (
        <MDBox
          display="flex" flexDirection="row"
          right={align !== "right" ? "16px" : 0}
          left={align === "right" ? "-5px" : "unset"}
          sx={({ typography: { size } }: any) => ({
            fontSize: size.lg,
          })}
        >
          <MDInput            
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }: any) => ({
              fontSize: size.lg,
              width: "13.1rem",
            })}
            size="small"
            label={ children }
            variant="outlined"
            inputRef={ emailInput }
            value={ searchEmail }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputEmail(); 
              debouncedHandleInput(emailInput.current.value);
            }}
            // onChange={handleInput( children )}
            // {(e: React.ChangeEvent<HTMLInputElement>) => handleInputSearch(e.target.value, children)}
            onKeyDown={(e:any) => handleSearchEnter( e, children )}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end"
                  sx={{ paddingLeft: "-2rem", paddingRight: "0rem", scale: "0.7"}}
                >
                  { searchEmail && searchEmail !== '' && ( 
                    <IconButton 
                      sx={{ paddingLeft: "0rem", paddingRight: "0.8rem" }}
                    edge="end" onClick={() => handleClearFilter( children )}>
                      <Icon>clear_icon_component</Icon>
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          >
          </MDInput>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0rem",
              lineHeight: "0rem",
              scale: '0.9',
            }}
          >
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'asc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'asc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'asc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_up</Icon> */}
            </MDBox>
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'desc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'desc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'desc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_down</Icon> */}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : children === 'Overené hlásenia' ? (
        <MDBox
          className="overeneHlaseniaHeader"
          display="flex" flexDirection="row"
          right={align !== "right" ? "16px" : 0}
          left={align === "right" ? "-5px" : "unset"}
          sx={({ typography: { size } }: any) => ({
            fontSize: size.lg,
            width: "7.1rem",
          })}
        >
          <MDBox
            component="th"
            width={width}
            py={1.5}
            // px={2}
            sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
            // borderBottom: `${borderWidth[1]} solid ${light.main}`,      
            position: "relative",
            textAlign: "left",
            opacity: "0.7",
            background: "transparent",
            color: "#7b809a",
            fontSize: "0.75rem",
            fontWeight: "700",
            wordWrap:"unset",
            width: "100%"
            })}
          >
            Overené hlásenia
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0rem",
              lineHeight: "0rem",
              scale: '0.9',
            }}
          >
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'asc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'asc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'asc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_up</Icon> */}
            </MDBox>
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'desc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'desc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'desc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_down</Icon> */}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : children === 'Všetky hlásenia' ? (
        <MDBox
          className="overeneHlaseniaHeader"
          display="flex" flexDirection="row"
          right={align !== "right" ? "16px" : 0}
          left={align === "right" ? "-5px" : "unset"}
          sx={({ typography: { size } }: any) => ({
            fontSize: size.lg,
            width: "6.8rem",
          })}
        >
          <MDBox
            component="th"
            width={width}
            py={1.5}
            // px={2}
            sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
            // borderBottom: `${borderWidth[1]} solid ${light.main}`,      
            position: "relative",
            textAlign: "left",
            opacity: "0.7",
            background: "transparent",
            color: "#7b809a",
            fontSize: "0.75rem",
            fontWeight: "700",
            wordWrap:"unset",
            width: "100%"
            })}
          >
            Všetky hlásenia
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0rem",
              lineHeight: "0rem",
              scale: '0.9',
            }}
          >
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'asc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'asc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'asc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_up</Icon> */}
            </MDBox>
            <MDBox
              sx={{ '&:hover': { opacity: 1, }, }}
              color={activeColumn === String(children) && sortDirection === 'desc' ? 'text' : 'secondary'}
              opacity={activeColumn === String(children) && sortDirection === 'desc' ? 1 : 0.5}
              style={{ cursor: 'pointer' }}
              onClick={() => handleAscDesc(String(children), 'desc')}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
              {/* <Icon>arrow_drop_down</Icon> */}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : children === 'Action' ? (
        <MDBox
            component="th"
            width={width}
            sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",    
            position: "relative",
            textAlign: "center",
            opacity: "0.7",
            background: "transparent",
            color: "#7b809a",
            fontSize: "2rem",
            fontWeight: "700",
            wordWrap:"unset",
            width: "100%",
            "&:hover": {
              opacity: 1, // Change opacity on hover
              cursor: "pointer",
            },
            })}
          >
            { searchValue || searchId || searchName || searchRole || searchEmail || searchBlockedAt ? (
              
              <ClearIcon onClick={handleClear} />
            ) : null
            }
       
          </MDBox>
      ) : (
        
        <>
          {/* {children} */}
        </>
      ) 
      }
    </MDBox>
  
  );
}

// Declaring default props for DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

export default DataTableHeadCell;
