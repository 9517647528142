/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import UsersList from "layouts/pages/user/users-list";
import AddUser from "layouts/pages/user/addUser";
import UserProfile from "layouts/pages/user/userProfile";
import EditUser from "layouts/pages/user/editUser";

import ReportsList from "layouts/pages/report/reports-list";
import ReportProfile from "layouts/pages/report/reportProfile";
import AddReport from "layouts/pages/report/addReport";

import SignInBasic from "layouts/authentication/sign-in";
import Magic from "layouts/authentication/sign-in/magiclink";

// @mui icons
import PeopleIcon from '@mui/icons-material/People';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import LogoutIcon from '@mui/icons-material/Logout';

const routes = [  
  
  {
    type: "collapse",
    name: "Používatelia",
    key: "user",
    icon: <PeopleIcon fontSize="medium">PeopleIcon</PeopleIcon>,
    route: "/pages/user/users-list",
    component: <UsersList />,
    noCollapse: true,
    queryParamNames: ["offset", "limit", "sort", "role"],
  },   
  {
    type: "collapse",
    name: "Hlásenia",
    key: "report",
    icon: <FmdBadIcon fontSize="medium">FmdBadIcon</FmdBadIcon>,
    route: "/pages/report/reports-list",
    component: <ReportsList />,
    noCollapse: true,
  },

  // user 
  {    
    name: "pridaj používateľa",
    key: "user",
    route: "/pages/user/addUser",
    component: <AddUser />,
  },
  {
    name: "profil používateľa",
    key: "user",
    route: "/pages/user/userProfile/:id",
    component: <UserProfile />,
  },  
  {
  name: "uprav používateľa",
  key: "user",
  route: "/pages/user/editUser/:id",
  component: <EditUser />,
  },  
  // report
  {    
    name: "pridaj hlásenie",
    key: "report",
    route: "/pages/report/addReport",
    component: <AddReport />,
  },
  {
    name: "profil hlásenia",
    key: "report",
    route: "/pages/report/reportProfile/:id",
    component: <ReportProfile />,
  },  
   
  
  // authentication
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Odhlásenie",
    key: "logout",
    icon: <LogoutIcon fontSize="medium">LogoutIcon</LogoutIcon>,
    route: "/authentication/sign-in",
    component: <SignInBasic />,
    noCollapse: true,
  },
  {
    name: "magic",
    key: "magic",
    route: "/authentication/sign-in/magiclink",
    component: <Magic />,
  },  
  { type: "divider", key: "divider-1" },
  
];

export default routes;
