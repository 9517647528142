
import React, { useEffect, useState, } from 'react'

import { useLocation, useNavigate, useParams, } from "react-router-dom";


// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import BlockUser from "components/BlockUser";
import UnBlockUser from "components/UnBlockUser";
import RejectReport from "components/RejectReport";

// Page components
import PersonalInfo from "layouts/pages/user/userProfile/components/PersonalInfo";
import Notification from "layouts/pages/user/userProfile/components/Notification";
import Card from "@mui/material/Card";
import DeleteUser from "components/DeleteUser";

// user profile data
import { extractDataUser } from "layouts/pages/user/userProfile/data/Data";
import { dataTableHeader, extractDataReport } from "layouts/pages/user/userProfile/data/dataTableData";

import DataTable from "./data/DataTable";

// types
import { UserDetail, Reports } from 'types/interface'

// axios
import { apiClient } from "api-client"


function UserProfile(): JSX.Element {

  // Take id from users-list
  let location = useLocation()

  const navigate = useNavigate();

  const { id } = useParams();

  const urlUsersList = '/pages/user/users-list';

  const [ user, setUser ] = useState<any>(null);
  const [ userId, setUserId ] = useState<number>(null);

  const [ isActiveDelete, setIsActiveDelete ] = useState(false);

  const [ isBlockWindow, setIsBlockWindow ] = useState(false);
  const [ isUnBlockWindow, setIsUnBlockWindow ] = useState(false);

  const [ filters, setFilters ] = useState('')

  // send data back to users list
  const backUsersList = () => {
    const filter = filters;
    const bool = true;
    navigate( (`/pages/user/users-list`), { state: {filter} } );
  }

  // /*
  // */ Handle user 
  //

  // refresh when receive from edit user true
  useEffect(() => {
    if ( location.state.bool === true )
    getUser( userId )
  },[location.state.bool]);

  // Get user
  const getUser = async ( id: number ) => {
    try {
      const response = await apiClient.get(`/v1/users/${id}/detail`);
      const query: UserDetail = extractDataUser(response.data);
      setUser( query )
      // console.log( query )

      } catch ( error ){
        console.log( 'err' )
      }
  }  

  // refresh when receive from users list id
  useEffect(() => {
    let userId = parseInt(id)
    setUserId( userId )
    getUser( userId )
    setFilters(location.state.filter)
  },[id, location.state.filter]);

  
  // handle block or unblock window
  const handleBlockUnblockWindow = () => {
    if ( user.blockedAt === "Aktívny" ) {
      return handleBlockUser();
    } else {
      return handleUnBlockUser();
    }
  }

  // if click on btn zrusit close the window
  const handleCloseWindow = ( bool: boolean ) => {
    if ( bool === true ) 
      setIsBlockWindow(false)
      setIsUnBlockWindow(false);
      setIsRejectReport(false)
  }

  // ///////// Block

  const [ isBlockUser, setIsBlockUser ] = useState<boolean>( false )
  const [ isUnBlockUser, setIsUnBlockUser ] = useState<boolean>( false )

  useEffect(() => {
    if ( isBlockUser === true )
    getUser( user.id )
    setIsBlockUser( false )
  },[isBlockUser]);


  // handle btn block user from popup window
  const handleBtnBlockUser = ( bool: boolean ) => { 
    if ( bool === true )   
      blockUser( userId );
      setIsBlockWindow(false);
      setIsBlockUser( true )
  }

  // Handle block user, open popup window
  const handleBlockUser = () => {
    setIsBlockWindow(true);    
  }

  // block user
  const blockUser = async ( id: number ) => {
    try {
      await apiClient.put(`/v1/users/${id}/block`
      ).then( ( response: any ) => {
        let query: any = response
        // setUser(query);
        getUser(id)
        
        console.log(query)        
        // console.log(user.id)        
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }
  
  // ///////////////  Unblock

  useEffect(() => {
    if ( isUnBlockUser === true )
    getUser( user.id )
    setIsUnBlockUser(false)
  },[isUnBlockUser]);

  // handle btn unblock user from popup window
  const handleBtnUnBlockUser = ( bool: boolean ) => {
    if ( bool === true)
      unBlockUser( userId )
      setIsUnBlockWindow(false);
      setIsUnBlockUser( true )
  }

  // Handle unblock user, open popup window
  const handleUnBlockUser = () => {
    setIsUnBlockWindow(true);       
  }

  // unblock user
  const unBlockUser = async ( id: number ) => {
    try {
      await apiClient.delete(`/v1/users/${id}/block`
      ).then( () => {
        getUser( id )
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }


  // /*
  // */ Handle delete user 
  // 
  
  // if click btn odstranit, show window with submit delete  
  const handleSubmitBtnDelete = () => {    
    setIsActiveDelete(true); 
  }

  // if submit delete, delete data from API ang go home page 
  const handleSubmitDelete = ( id: number ) => {
    deleteUser( id );
    setIsActiveDelete( false )      
    backTable();    
  }

  // If click on Zrusit, close the window
  const handleCloseDeleteWindow = ( bool: boolean) => {
    if ( bool === true ) setIsActiveDelete( false )
  }
  
  // delete user data
  const deleteUser = async ( id: number ) => {
    try {
      await apiClient.delete(`/v1/users/${id}`
      ).then( ( response: any ) => {  
        console.log(response)
      });
    } catch ( error ){
        console.log( 'err' )
    }
  }


  // If click edit icon go to edit user  
  const handleSubmitEdit = ( data: string ) => {
    const id = userId;
    if ( data === 'true' ) {      
    navigate ( (`/pages/user/editUser/${userId}`), {state:{ id }} );
    }
  }  

  // back to table-users page
  const backTable = () => {
    navigate( urlUsersList );
  };

  // /*
  // */ Handle user reports
  // 

  
  const [ reports, setReports ] = useState<any>()

  const [ totalReports, setTotalReports ] = useState<number>();

  const [ sortColumn, setSortColumn ] = useState<string>( 'desc' )
  const [ lastSortColumnType, setLastSortColumnType ] = useState<string>('id')
  const [ isSortColumn, setIsSortColumn ] = useState<boolean>(false)
  const [ isEnter, setIsEnter ] = useState<boolean>(false)

  const [ page, setPage ] = useState<number>(0);
  const [ rowLimit, setRowLimit ] = useState<number>( 8 );
  const [ numberOfPages, setnumberOfPages ] = useState<number>( );
  const [ offset, setOffset ] = useState<number>( 0 );

  const [ isNextPage, setIsNextPage ] = useState(false);
  const [ isPreviousPage, setIsPreviousPage ] = useState(false);
  const [ isBtnPage, setIsBtnPage ] = useState(false);

  
  // Get reports list
  const getReports = async () => {
    try {
      await apiClient.get(`/v1/users/${userId}/incidents`,{
        params: { 
          offset: offset,
          limit: rowLimit,
          sort: lastSortColumnType +':'+ sortColumn,
        }}
      ).then( response => {
        let query: Reports[] = response.data.items.map(
          (query: Reports) => extractDataReport( query ))
        
        let total = response.data.totalCount
        let numOfPages = Math.ceil(total / rowLimit);
      
        // console.log(response.data.items);
        setReports( query );
        setTotalReports( total );
        setnumberOfPages( numOfPages );
        setIsEnter( false )
        // console.log( response.data.items )
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }  

  useEffect(() => {
    getReports()
  },[userId]);


  const [ reportId, setReportId ] = useState<any>();

   // send data to profile page
   const handleProfileReport = ( data: Reports ) => {
    const id = data.id;
    navigate( ( `/pages/report/reportProfile/${id}` ), {state:{ id }} );
  }

  // /*
  // */ Handle reject report 
  //

  const [ isRejectReport, setIsRejectReport ] = useState(false);
  
  // handle btn reject user
  const handleBtnRejectReport = ( bool: boolean ) => {
    if ( bool === true)
      rejectReport( reportId )
      setIsRejectReport(false);
      // console.log('reject')
  }

  // Handle click on remove btn, open approve window and take id from child
  const handleIdRejectReport = ( idReport: number ) => {
    setIsRejectReport(true); 
    setReportId( idReport )   
  }

  // reject report
  const rejectReport = async ( id: number ) => {
    try {
      await apiClient.delete(`/v1/incidents/${id}`
      ).then( ( response ) => {
        // console.log( response )

        getReports()
      });
    } catch ( error ){
        console.log( 'err' )
      }
  }

  // /*
  // */ Handle setting page 
  // 
  
  // take row limit from child
  const handleRowLimit = (num: number) => {
    setRowLimit(num)
    // console.log(rowLimit)
  }

  // Handle actual page
  const setActualPage = () => {
    let actualPage = ( offset / rowLimit );
    let page = actualPage + 1;
    setPage( actualPage )
    setNewInputValue( page )
  }

  // after click on btn arrow next set offset
  const handleNextPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset < ( (numberOfPages * 10) - 10 ) ){
      let value = offset + rowLimit
      setOffset( value );
      setIsNextPage( true );
    }
  }

  // refresh if click on arrow next
  useEffect( () => {
    if ( isNextPage === true ) {
      setIsNextPage(false);
      getReports()
      setActualPage()
    }
  }, [offset,page]);

  // after click on btn arrow previous set offset
  const handlePreviousPage = ( boolValue: boolean ) => {
    if ( boolValue === true && offset > 0 ){
      let offsetValue = offset - rowLimit
      setOffset( offsetValue );
      setIsPreviousPage( true );      
    }
  }

  // refresh if click on arrow previous
  useEffect( () => {
    if ( isPreviousPage === true ) {
      setIsPreviousPage(false);
      getReports()
      setActualPage()
      // console.log( offset )
    }
  }, [offset,page]);

  // after click on btn number set offset
  const handleBtnPage = ( btnPage: number ) => {
      let offsetValue = btnPage * rowLimit;
      setOffset( offsetValue );
      setIsBtnPage( true );         
  }

  // refresh if click on arrow previous
  useEffect( () => {
    if ( isBtnPage === true ) {
      setIsBtnPage(false);
      getReports()
      setActualPage()
    }
  }, [offset,page]);

  // Handle column sort
  const handleSortColumn = ( column: string ) => {
    
    setLastSortColumnType( column )

    if ( lastSortColumnType === column && sortColumn === 'asc' ){
      setSortColumn( 'desc' )
      setIsSortColumn( true )
    } else {
      setSortColumn( 'asc' )
      setIsSortColumn( true )
    }
  }

  // refresh if sort data
  useEffect( () => {
    if ( isSortColumn ) {
      setIsSortColumn( false );
      getReports()
    }
  }, [ isSortColumn ]);

  /////////////////// handle page if length pages > 6

  const [newInputValue, setNewInputValue] = useState( page + 1)

   // take from child new number in input
  const handleInputValue = ( num: number ) => {
    setNewInputValue( num )
  }

  // if push enter, render entries acc input number
  const handleEnter = ( bool: boolean ) => {
    if ( bool === true ) {
      let newPage = newInputValue - 1 ;
      let newOffset = newPage * 10 ;
      setPage( newPage );
      setOffset( newOffset );
      setIsEnter(true);     
    }
  } 

   // refresh if push enter
   useEffect( () => {
    if ( isEnter ) {
      getReports()
      setIsEnter( false );
    }
  }, [ isEnter ]);

    // Handle active sorting arrows
    const [activeColumn, setActiveColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('');
  
    const handleAscDesc = (column:string, sort:string) => {
      setActiveColumn(column)
      setSortDirection(sort);
    };

  return (
    <MDBox
    sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
    display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "1rem"
  }}
    >
      <MDBox container
        sx={{
          pb: "1rem", display: "flex",
          direction: "row", gap: "1rem", width: "100%", 
        }} >          

        <MDBox item width={7} xs={1.3} 
          sx= {{
            display: "flex",    
            justifyContent: "flex-start",  
            width: "33%",                      
          }} >
          <MDButton 
            variant="outlined" 
            color="success" 
            // onClick={() => navigate(-1)}
            onClick={ backUsersList }
            >
            Naspäť
          </MDButton>
        </MDBox>
        
        <MDBox 
          sx= {{
            display: "flex", justifyContent: "flex-end", 
            gap: "1rem", width: "67%",           
          }} >

          <MDButton 
            variant="gradient" 
            color="light" 
            sx={{ }}
            onClick={handleBlockUnblockWindow}
          >
            { (user && user.blockedAt === "Aktívny") ? 'Blokovať' : 'Odblokovať' }                  
          </MDButton>                    

          <MDButton variant="gradient" 
            color="error" 
            sx={{ }}
            onClick={handleSubmitBtnDelete}
          >
            Odstrániť
          </MDButton>
        </MDBox>          

      </MDBox>
      
      <MDBox
        sx={{
          display: "flex",
          flexDirections: "row",
          }} >

        <MDBox 
          sx={{
            display: "block", width: "28%",
            marginRight: "1rem", pb: "1rem",           
            }} >

          <MDBox
            sx={{       
              width: "100%",
              pb: "1rem",
            }}>
            <PersonalInfo 
              user={user ? user : ''}
              onSubmitEdit={handleSubmitEdit}
              />              
          </MDBox>
          <MDBox             
            sx={{   
              width: "100%",                
            }}>
            <Notification 
              user={user ? user : ''}                                />
          </MDBox>
          
        </MDBox>

        <Card sx={{ width: "72%", height: "fit-content", marginBottom: "1rem" }} >
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Hlásenia
            </MDTypography>            
          </MDBox>
          <DataTable
            tableColumns={ dataTableHeader.columns } 
            tableRows={ reports ? reports : [] }
            onProfileReport={ handleProfileReport }
            onIdRejectReport={ handleIdRejectReport }

            onRowLimit={ handleRowLimit }
            totalReports={ totalReports }
            actualPage={ page }
            countPages={ numberOfPages }
            onNextPage={ handleNextPage }
            onPreviousPage={ handlePreviousPage }
            onBtnPage={ handleBtnPage }
            onSortColumn={ handleSortColumn }
            onInputValue={ handleInputValue }
            newInputValue={ newInputValue }
            onEnter={ handleEnter }

              
            activeColumn={activeColumn}
            sortDirection={sortDirection}
            onAscDesc={handleAscDesc}
          />
          { ( isRejectReport === true ) ? 
          <RejectReport
            onCloseWindow={handleCloseWindow}
            onBtnRejectReport={handleBtnRejectReport}
          /> : ''}

        </Card>

      </MDBox>



      { isActiveDelete && (
        <DeleteUser 
          user={user}
          onCloseDeleteWindow={handleCloseDeleteWindow}
          onSubmitDelete={handleSubmitDelete}
          />
      )}

      { ( isBlockWindow === true ) ?
        <BlockUser
        onCloseWindow={handleCloseWindow}
        onBtnBlockUser={handleBtnBlockUser}
        /> : '' }
      
      { ( isUnBlockWindow === true ) ?
        <UnBlockUser
        onCloseWindow={handleCloseWindow}
        onBtnUnBlockUser={handleBtnUnBlockUser}
        /> : '' }
    </MDBox>
  );
}

export default UserProfile;