
import { format, utcToZonedTime } from 'date-fns-tz';

// types
import { Reports } from "types/interface"


// /*
// */ Data table Header
// 

// Define the type for the 'columns' property
interface DataTableColumn {
  Header: string;
  accessor: string;
  width: string;
}

// Define the type for the 'dataTableHeader' object
interface DataTableHeader {
  columns: DataTableColumn[];
}

export const dataTableHeader: DataTableHeader = {
  columns: [
    { Header: "Id", accessor: "id", width: "7%" },
    { Header: "Typ", accessor: "type", width: "9%" },
    { Header: "Lokalita", accessor: "location", width: "11%" },
    { Header: "Dátum a čas", accessor: "occurredAt", width: "13%" },
    { Header: "Stav", accessor: "status", width: "10%" },
    { Header: "Nahlásené", accessor: "reportedAt", width: "10%" },
  ],
};

// const dateTimeOccurredAt = utcToZonedTime(occurredAt, 'Europe/Bratislava');
  // const formattedOccurredAt = format(dateTimeOccurredAt, 'dd.MM.yyyy HH:mm');

export const extractDataReport = ({
  id,
  type,
  location,
  occurredAt,
  reporter,
  status,
  reportedAt
}: Reports) => {
  const { coordinates, name } = location;
  const locationValue = name;

  const fullName = reporter?.fullName ?? 'Unknown Reporter'; // Use 'Unknown Reporter' if reporter is undefined

  let translateStatus = translationStatus(status);
  let translateType = translationType(type);

  // let dateTimeOccurredAt = parseISO(occurredAt);
  let dateTimeOccurredAt = utcToZonedTime(occurredAt, 'Europe/Bratislava');
  let formattedOccurredAt = format(dateTimeOccurredAt, 'dd.MM.yyyy HH:mm');

  // let dateTimereportedAt = parseISO(reportedAt);
  let dateTimereportedAt = utcToZonedTime(reportedAt, 'Europe/Bratislava');
  let formattedreportedAt = format(dateTimereportedAt, 'dd.MM.yyyy HH:mm');

  return {
    id,
    type: translateType,
    location: locationValue,
    occurredAt: formattedOccurredAt,
    fullName,
    status: translateStatus,
    reportedAt: formattedreportedAt,
  };
};



// translate status eng => sk
const translationStatus = ( status: string ) => {
  if ( status === 'pending' ) {
    return 'Čaká na schválenie';
  } else if ( status === 'confirmed' ) {
    return 'Schválené';
  } else if ( status === 'rejected' ) {
    return 'Zamietnuté';
  }
}

// translate type eng => sk
const translationType = ( type: string ) => {
  if ( type === 'tracks' ) {
    return 'Stopy';
  } else if ( type === 'scat' ) {
    return 'Trus';
  } else if ( type === 'bear_tree' ) {
    return 'Medvedí strom';
  } else if ( type === 'encounter' ) {
    return 'Stret';
  }
}




// export data
const dataTableData = {
  dataTableHeader,
  extractDataReport,
};

export default dataTableData;



