/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

// Declaring props types for DataTableHeadCell
interface Props {
  width?: string | number;
  children: ReactNode;
  sorted?: false | "none" | "asce" | "desc";
  align?: "left" | "right" | "center";

  activeColumn:string; 
  sortDirection:string; 
  onAscDesc:( column:string, sort:string ) => void;
}

function DataTableHeadCell({ 
  width, 
  children, 
  sorted, 
  align, 
  activeColumn, 
  sortDirection,
  onAscDesc, 
  ...rest }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

    // handle sort column
    const handleAscDesc = ( column: string, sort: 'asc' | 'desc' ) => {
      // onSortColumn( column, ascDesc )
      onAscDesc( column, sort )    
    }

  return (
    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }: Theme) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
          display: "flex", flexDirection: "row", alignItems: "center", gap: "0.6rem"
        })}
      >
        {children}
        {sorted && children !== "Lokalita" && (
        
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "0rem",
            lineHeight: "0rem",
            scale: '0.8',
          }}
        >
          <MDBox
            sx={{ '&:hover': { opacity: 1, }, }}
            color={activeColumn === String(children) && sortDirection === 'asc' ? 'text' : 'secondary'}
            opacity={activeColumn === String(children) && sortDirection === 'asc' ? 1 : 0.5}
            style={{ cursor: 'pointer' }}
            onClick={() => handleAscDesc(String(children), 'asc')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
              <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
            {/* <Icon>arrow_drop_up</Icon> */}
          </MDBox>
          <MDBox
            sx={{ '&:hover': { opacity: 1, }, }}
            color={activeColumn === String(children) && sortDirection === 'desc' ? 'text' : 'secondary'}
            opacity={activeColumn === String(children) && sortDirection === 'desc' ? 1 : 0.5}
            style={{ cursor: 'pointer' }}
            onClick={() => handleAscDesc(String(children), 'desc')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
            {/* <Icon>arrow_drop_down</Icon> */}
          </MDBox>
        </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

// Declaring default props for DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

export default DataTableHeadCell;
