
import React from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// icons
import LockIcon from '@mui/icons-material/Lock';

import { useMemo, useEffect, useState } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// icon
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "layouts/pages/user/users-list/data/DataTable/DataTableHeadCell";
import DataTableBodyCell from "layouts/pages/user/users-list/data/DataTable/DataTableBodyCell";

import { Users } from "types/interface"

// Declaring props types for DataTable
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  // table: {
  //   columns: { [key: string]: any }[];
  //   rows: { [key: string]: any }[];
  // };
  tableColumns: { [key: string]: any }[];
  tableRows: { [key: string]: any }[];
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;

  // offset: number;
  totalUsers: number;
  onEditUser: any;
  onBlockUser: any;
  onUnBlockUser: any;
  onProfileUser: any;
  numberOfPages: number;
  rowLimit: number;
  onRowLimit: ( num: number ) => void;
  actualPage: number;
  onBtnPage: ( key: number ) => void;
  onNextPage: ( boolValue: boolean ) => void;
  onPreviousPage: ( boolValue: boolean ) => void;
  onInputValue: ( num: number ) => void;
  newInputValue: number;
  onEnter: ( bool: boolean ) => void; 
  onEnterSearch?: ( bool: boolean, children: any ) => void;
  onSortColumn: ( column: string, ascDesc: string ) => void;
  onUserId?: ( id: string ) => void;
  onUserName?: ( name: string ) => void;
  onUserEmail?: ( email: string) => void;
  onUserRole?: ( role: string ) => void;
  onUserBlockedAt?: ( blockedAt: string ) => void;
  onClearInput: ( bool: boolean, column: string | number ) => void; 
  onClearInputs: ( bool: boolean ) => void; 
  
  searchId?: string;
  searchName?: string;
  searchRole?: string;
  searchEmail?: string;
  searchBlockedAt?: string;

  searchValue?: string;

  onCombineSort?: ( dataSearch: string | number, columnName: string, columnSort: string, sort: string ) => void;
  
  activeColumn:string; 
  sortDirection:string; 
  onAscDesc:( column:string, sort:string ) => void;
  onInputBounce: (bool: any) => void;
}

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  tableColumns,
  tableRows,
  pagination,
  isSorted,
  noEndBorder,

  totalUsers,
  onProfileUser,
  onEditUser,
  onBlockUser,
  onUnBlockUser,
  numberOfPages,
  actualPage,
  rowLimit,
  onBtnPage,
  onRowLimit,
  onNextPage,
  onPreviousPage,
  onInputValue,
  newInputValue,
  onEnter,
  onEnterSearch,
  onSortColumn, 
  onUserId,
  onUserName,
  onUserEmail,
  onUserRole, 
  onUserBlockedAt,
  onClearInput,
  onClearInputs,
  
  searchId,
  searchName,
  searchRole,
  searchEmail,
  searchBlockedAt,

  searchValue,

  onCombineSort,
  
  activeColumn, 
  sortDirection, 
  onAscDesc,
  onInputBounce,
}: Props): JSX.Element {
  let defaultValue: any;
  let entries: any[];

  
  if (entriesPerPage) {
    // defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : "10";
    defaultValue = rowLimit ;
    entries = entriesPerPage.entries ? entriesPerPage.entries : ["10", "25", "50", "100"];
  }

  const columns = useMemo<any>(() => tableColumns, [tableColumns]);
  const data = useMemo<any>(() => tableRows, [tableRows]);
 
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = tableInstance;

  const pageOptions: any = []  

  for (let i = 0; i <= numberOfPages-1; i++) {
    pageOptions.push(i);
  }

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => {setPageSize(value);
  }

  // Render the paginations
  const renderPagination = pageOptions.map((option: any) => (
    <MDPagination
      item
      className={ option }
      key={ option }
      onClick={
        ( (e:any) => handleBtnNumber( e, option ) )
      }
      active={ ( actualPage  ) === option}
    >
      { option + 1 }
    </MDPagination>     

  ));

  const handleBtnNumber = ( e: any, key: any ) => {
    e.preventDefault();
    onBtnPage( key );
  }

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // Setting the entries starting point
  const entriesStart = actualPage === 0 ? actualPage + 1 : (+actualPage) * (+rowLimit) + 1 ;

  // Setting the entries ending point
  let entriesEnd;

  if (actualPage === 0) {
    entriesEnd = (+rowLimit);
  } else if ( (+actualPage +1 ) === pageOptions.length ) {
    entriesEnd = totalUsers;      
  } else {
    entriesEnd = (+rowLimit) * (actualPage + 1);      
  }

  // on click back to parent data
  const handleProfileClick = ( e:any, user: Users ) => {
    e.preventDefault()        
    onProfileUser( user )  
  }
  
  // If click on edit btn send data to parent
  const handleEditClick = ( e: React.MouseEvent<HTMLElement> , user: Users ) => {
    e.stopPropagation()

    onEditUser( user )
  }

  // If click on block btn send data to parent
  const handleBlockClick = ( e: React.MouseEvent<HTMLElement> , user: Users ) => {
    e.stopPropagation()
    console.log(user.blockedAt);
    (user.blockedAt === 'Blokovaný') ? onUnBlockUser( user ) : onBlockUser( user );
    // onBlockUser( user )
  }

  // after change row limit send to parent number
  const getRowLimit = ( limit: number ) => {
    onRowLimit(limit)
  }  
  
  const SubmitNextPage = () => {
    onNextPage( true )
  }
  
  const SubmitPreviousPage = () => {
    onPreviousPage( true )
  }

  const toEntries = ( entries: number ) => {
    return entries
  }
  
  // send parent new value from input
  // if newValue is bigger then number of pages send biggest page
  const handleChange = (event: any ) => {
    const newValue = event.target.value;
    if ( newValue > numberOfPages ) {
      onInputValue(numberOfPages)
    } else {
      onInputValue(newValue);
    }
  };

  // if click enter go to page in input page
  const handleKeyPress = ( event: any ) => {
    if (event.key === 'Enter') {
      onEnter( true )
    }
  };   
      
  return (

    <TableContainer sx={{ boxShadow: "none" }}> 
      {/* Table header      */}
      <Table {...getTableProps()}>
        <MDBox component="thead" >
          {headerGroups.map((headerGroup: any, key: string) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              
              {headerGroup.headers.map((column: any, key: any) => (            
                
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}               
                  onEnterSearch={onEnterSearch}
                  onSortColumn={ onSortColumn }
                  onUserId={ onUserId }
                  onUserName={ onUserName }
                  onUserEmail={ onUserEmail }
                  onUserRole={ onUserRole }
                  onUserBlockedAt={ onUserBlockedAt }
                  onClearInput={ onClearInput }
                  onClearInputs={ onClearInputs }
                  searchRole={ searchRole }

                  searchId={ searchId }
                  searchName={ searchName }
                  searchEmail={ searchEmail }
                  searchBlockedAt={ searchBlockedAt }

                  searchValue={ searchValue }
                  onCombineSort={ onCombineSort }

                  activeColumn={activeColumn}
                  sortDirection={sortDirection}
                  onAscDesc={onAscDesc}

                  onInputBounce={ onInputBounce }
                >
                  {column.render("Header")}
                  
                </DataTableHeadCell>
                       
              ))}

            {/* Action column name  */}
              {/* <DataTableHeadCell
                width="4%"
                align="center"
                sorted={null}
              >
                Akcie
              </DataTableHeadCell> */}
            </TableRow>
          ))}
        </MDBox>

        <TableBody {...getTableBodyProps()} >
          
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow
        style={{ cursor: 'pointer' }}
        className=""
        onClick={(e: any) => handleProfileClick(e, row.values)}
        key={key}
        {...row.getRowProps()}
      >
        {row.cells.map((cell: any, key: any) => (
          <DataTableBodyCell
            key={key}
            noBorder={noEndBorder && rows.length - 1 === key}
            align={
              cell.column.Header === 'Overené hlásenia' ||
              cell.column.Header === 'Všetky hlásenia' ||
              cell.column.Header === 'Všetky hlásenia'
                ? 'center'
                : cell.column.align
                ? cell.column.align
                : 'left'
            }
            {...cell.getCellProps()}
            data-row-index={key}
            style={{ align: 'center' }}
          >
            {/* Render different content based on the column name */}
           {cell.column.Header === 'Action' ? (
              <>
                {row.values.role !== 'Administrátor' ? (
                  <MDBox position="relative" padding="0rem 0rem 0rem 0rem" top="0">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                      <Tooltip title="Upraviť">
                        <IconButton
                          className="edit"
                          onClick={(e) => handleEditClick(e, row.values)}
                          aria-label="Edit"
                          size="small"
                        >
                          <ManageAccountsIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Blokovať">
                        <IconButton
                          aria-label="Block"
                          size="small"
                          onClick={(e) => handleBlockClick(e, row.values)}
                        >
                          <LockIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </MDBox>
                ) : null}
              </>
            ) : (
              // Render cell content for other columns
              cell.render('Cell')
            )}
          </DataTableBodyCell>
        ))}
      </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Zobrazuje sa&nbsp;
                { (entriesStart > totalUsers ) ? totalUsers : entriesStart }
                &nbsp;až&nbsp; 
                { (entriesEnd > totalUsers ) ? totalUsers : entriesEnd } 
                &nbsp;z&nbsp;{totalUsers}&nbsp;položiek
            </MDTypography>
          </MDBox>
        )}

        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {/* {canPreviousPage && ( */}
            {/* { pageOptions.length > 1 && actualPage > 0 && ( */}
            { actualPage >= 1 && newInputValue >= 1 && (
              <MDPagination item onClick={() => {
                // previousPage()
                SubmitPreviousPage()}}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="3rem" mx={1}>
                <MDInput
                  // inputProps={{ type: "number", min: 1, max: pageOptions.length }}
                  // value={ actualPage + 1 }
                  value={ newInputValue }
                  onChange={(event: any) => {
                    // handleInputPagination(event);
                    // handleInputPaginationValue(event);
                    handleChange(event)
                  }}   
                  onKeyDown={handleKeyPress}
                />
              </MDBox>
            ) : (
              renderPagination
            )}            
            {/* {canNextPage && ( */}
            { pageOptions.length > 1 && ( (actualPage + 1) < pageOptions.length ) &&(
              <MDPagination item 
                onClick={() => {
                  // nextPage()
                  SubmitNextPage()} }
                // onClick={handleButtonClick}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>

      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" 
              alignItems="center" px={3} pb={3} pt={1}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                  getRowLimit(newValue)
                  toEntries(newValue)
                  // countOfPages(newValue)
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;položiek na stránku
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [ "10", "25", "50" ] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
