


export const dataTableHeader = {
  columns: [
    { Header: "Id", accessor: "id", width: "8%" },
    { Header: "Meno", accessor: "fullName", width: "17%" },
    { Header: "Rola", accessor: "role", width: "13%" },
    { Header: "E-mail", accessor: "email", width: "21%" },
    { Header: "Stav", accessor: "blockedAt", width: "11%" },
    { Header: "Overené hlásenia", accessor: "confirmedIncidents", width: "10%" },
    { Header: "Všetky hlásenia", accessor: "reportedIncidents", width: "10%"},
    { Header: "Action", accessor: "Action", width: "auto"},
  ],
  
};

export interface BlockedAtOption {
  value: string;  
}

export const blockedAt: BlockedAtOption[] = [
  { value: 'Aktívny' },
  { value: 'Blokovaný' },
];


// export data
const dataTableData = {
  dataTableHeader,
  blockedAt,
};

export default dataTableData;
