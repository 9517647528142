
import { ReactNode, useRef } from "react";

import DOMPurify from "dompurify";

// @mui material components
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

import { IconButton, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";


// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { debounce } from 'lodash'

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "context";

import 'css/dataTable.css';

// Declaring props types for DataTableHeadCell
interface Props {
  width?: string | number;
  children: ReactNode;
  sorted?: false | "none" | "asce" | "desc";
  align?: "left" | "right" | "center";
  onEnterSearch?: ( bool: boolean, children: any ) => void;
  onSortColumn?: ( child: any, ascDesc: string ) => void;

  searchValue?: string;
  onUserId?: ( id: string ) => void;
  onUserName?: ( role: string ) => void;
  onReportType?: ( role: string ) => void;
  onReportStatus?: ( role: string ) => void;
  onClearInput?: ( bool: boolean, column: string | number ) => void;
  onClearInputs?: ( bool: boolean ) => void;

  searchId?: string;
  searchType?: string;
  searchOccurredAt?: any;  
  searchStatus?: string;
  searchFullName?: string;   
  searchReportedAt?: any;

  onCombineSort?: ( dataSearch: string | number, columnName: string, columnSort: string, sort: string, dataSearchTo?: string | number ) => void;

  activeColumn:string; 
  sortDirection:string; 
  onAscDesc:( column:string, sort:string ) => void;
  onDateRangePicker: ( bool: boolean, column: any ) => void;
  onInputBounce: (bool: any) => void;
}

function DataTableHeadCell({ 
  width, 
  children, 
  sorted, 
  align,
  onEnterSearch,
  onSortColumn, 
  onClearInput,
  onClearInputs,

  searchValue,
  onUserId, 
  onUserName,
  onReportType,
  onReportStatus,

  searchId,
  searchType,
  searchOccurredAt,
  searchStatus,
  searchFullName,
  searchReportedAt,

  onCombineSort,
  activeColumn, 
  sortDirection, 
  onAscDesc,

  onDateRangePicker,

  onInputBounce,
  ...rest }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const status = [ 'Schválené', 'Zamietnuté', 'Čaká na schválenie' ];
  const type = [ 'Stopy', 'Trus', 'Medvedí strom', 'Stret' ];

  const idInput = useRef<HTMLInputElement>(null)
  const typeInput = useRef<HTMLInputElement>(null)
  const statusInput = useRef<HTMLInputElement>(null)
  const fullNameInput = useRef<HTMLInputElement>(null)

  const handleSearchEnter = ( e: any, column: any ) => {
    if (e.key === 'Enter') {
      onEnterSearch( true, column )
    }
  }

  const debouncedHandleInput = debounce(( value: any ) => {
    onInputBounce('true');
  }, 1000);

  // get data from id input
  const handleInputId = () => {
    let searchString = DOMPurify.sanitize( idInput.current?.value);
    onUserId( searchString );     
  };

  const handleInputName = () => {
    let searchString = DOMPurify.sanitize( fullNameInput.current?.value);
    onUserName( searchString );     
  };

  // get data from role input
  const getInputType = ( e:any, newValue: any ) => {
    if ( newValue && newValue.value ){
      onReportType( newValue.value )
    } else if ( newValue ) { 
      onReportType(newValue) 
    }    
  }

   // get data from role input
  const getInputStatus = ( e:any, newValue: any ) => {
    if ( newValue && newValue.value ){
      onReportStatus( newValue.value )
    } else if ( newValue ) {
      onReportStatus(newValue)
    }
  }
  
  // handle sort column
  const handleAscDesc = ( column: any, sort: string ) => {
    onAscDesc( column, sort )

    if ( searchId ) {
      onCombineSort( searchId, 'id', column, sort )
    } else if ( searchType ) {
      onCombineSort( searchType, 'type', column, sort )
    } else if ( searchStatus ) {
      onCombineSort( searchStatus, 'status', column, sort )
    } else if ( searchFullName ) {
      onCombineSort( searchFullName, 'fullName', column, sort )
    } else if ( searchOccurredAt ) {
      onCombineSort( 'occurredAt', 'occurredAt', column, sort )
      console.log( searchOccurredAt )
    } else if ( searchReportedAt ) {
      onCombineSort( 'reportedAt', 'reportedAt', column, sort )
    } else {
      onSortColumn( column, sort )
    }
  }

  const handleClear = () => {
    onClearInputs(true)
  }
  
  const handleClearFilter = ( column: any ) => {
    onClearInput( true, column );    

  }

  const handleDatePicker = ( column: any ) => {
    onDateRangePicker( true, column )
  }

  return (
    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={2}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
   
    {children === 'Typ' ? (
    <MDBox
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
      })}
    >     
      <Autocomplete
        options={[...type, { label: 'Zrušiť výber', value: 'clear' }]}
        value={ searchType ? searchType : '' }
        inputValue={ searchType ? searchType : '' }
        onChange={getInputType} 
        disableClearable   
        // sx={{ "& div": { minWidth: "25rem !important", width: "20rem" } }} 
        ListboxProps= {{
          sx: {
            "& div": {  minWidth: "25rem !important", width: "20rem"  },
            "& li": {  marginLeft: "-0.75rem", marginRight: "0rem", fontSize: "0.85rem !important", borderRadius: "0px !important", },
            "& li:last-child": { color: "#000", backgroundColor: "#FF7866", borderRadius: "0px", fontWeight: "400"},
            "& div .css-3u5isr-MuiPopper-root-Autocomplete-popper": { minWidth: "25rem !important" }
          }
        }}
        renderInput={(params) => (
        <MDInput
          {...params}
          right={align !== 'right' ? '16px' : 0}
          left={align === 'right' ? '-5px' : 'unset'}
          sx={{
            width: "8.7rem",
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              marginRight: 0, 
              right: "5px",
            },
            '& button': {
              paddingRight: "0rem",
              paddingLeft: "0rem",
            },                  
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingRight: '0', 
              paddingLeft: '0.3rem', 
              fontSize: '0.75rem',             
            },
          }}
          size="small"
          label={children}
          variant="outlined"
          inputRef={typeInput}
          placeholder="Typ"          
        />
        )}
      />
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9',
        }}
      >
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'asc') }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
        </MDBox>
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </MDBox>
      </MDBox>

    </MDBox>

  ) : children === 'Stav' ? (
    <MDBox
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
      })}
    >     
      <Autocomplete
        options={[...status, { label: 'Zrušiť výber', value: 'clear' }]}
        value={searchStatus}
        inputValue={searchStatus}
        onChange={getInputStatus} 
        disableClearable       
        ListboxProps= {{
          sx: {
            "& div": {  minWidth: "25rem !important", width: "20rem"  },
            "& li": {  marginLeft: "-0.75rem", marginRight: "0rem", fontSize: "0.85rem !important", borderRadius: "none", },
            "& li:last-child": { color: "#000", backgroundColor: "#FF7866", borderRadius: "0px", fontWeight: "400"},
          }
        }}
        renderInput={(params) => (
        <MDInput
          {...params}
          right={align !== 'right' ? '16px' : 0}
          left={align === 'right' ? '-5px' : 'unset'}
          sx={{
            width: "10rem",
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              marginRight: 0, 
              right: "5px",
            },
            '& button': {
              paddingRight: "0rem",
              paddingLeft: "0rem",
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingRight: '0', 
              paddingLeft: '0.3rem', 
              fontSize: '0.75rem', 
            },
          }}
          size="small"
          label={children}
          variant="outlined"
          inputRef={statusInput}
          placeholder="Stav"
          // endAdornment={
          //   searchStatus ? (
          //     <ClearIcon
          //       sx={{
          //         position: 'absolute',
          //         right: 8,
          //         top: '50%',
          //         transform: 'translateY(-50%)',
          //         cursor: 'pointer',
          //       }}
          //       onClick={handleClear}
          //     />
          //   ) : null
          // }
        />
        )}
      />
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9',
        }}
      >
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'asc') }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
        </MDBox>
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </MDBox>
      </MDBox>

    </MDBox>
  ) : children === 'Id' ? (
    <MDBox
          display="flex" flexDirection="row"
          right={align !== "right" ? "16px" : 0}
          left={align === "right" ? "-5px" : "unset"}
          sx={({ typography: { size } }: any) => ({
            fontSize: size.lg,
          })}
    >
      <MDInput            
        right={align !== "right" ? "16px" : 0}
        left={align === "right" ? "-5px" : "unset"}
        sx={({ typography: { size } }: any) => ({
          fontSize: size.lg,
          width: "100%",
          paddingRight: "0rem",
          '& .css-qnqh4t-MuiInputBase-input-MuiOutlinedInput-input': {
              paddingRight: "0rem", 
              // width: "90%"                  
            },
          '& .css-fnfwl6-MuiInputBase-root-MuiOutlinedInput-root': {
              paddingRight: "0rem", 
              // width: "90%"                  
            },
          })
        }
        size="small"
        label={children}
        variant="outlined"
        inputRef={idInput}
        value={ searchId }
        // onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleInput( idInput.current.value, children ) }
        // onChange={ handleInputId }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleInputId(); 
          debouncedHandleInput(idInput.current.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"
              sx={{ paddingLeft: "-2rem", paddingRight: "0rem", scale: "0.7"}}
            >
              { searchId && ( 
                <IconButton 
                  sx={{ paddingLeft: "0rem", paddingRight: "0.8rem" }}
                edge="end" onClick={() => handleClearFilter( children )}>
                  <Icon>clear_icon_component</Icon>
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
         
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9',
        }}
      >
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'asc') }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
        </MDBox>
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </MDBox>
      </MDBox>

    </MDBox>
  ) : children === 'Nahlasovateľ' ? (
    <MDBox
      display="flex" flexDirection="row"
      right={align !== "right" ? "16px" : 0}
      left={align === "right" ? "-5px" : "unset"}
      sx={({ typography: { size } }: any) => ({
        fontSize: size.lg,
      })}
    >
      <MDInput            
        right={align !== "right" ? "16px" : 0}
        left={align === "right" ? "-5px" : "unset"}
        sx={({ typography: { size } }: any) => ({
          fontSize: size.lg,
          width: "100%",
          paddingRight: "0rem",
          '& .css-qnqh4t-MuiInputBase-input-MuiOutlinedInput-input': {
              paddingRight: "0rem", 
              // width: "90%"                  
            },
          '& .css-fnfwl6-MuiInputBase-root-MuiOutlinedInput-root': {
              paddingRight: "0rem", 
              // width: "90%"                  
            },
          })
        }
        size="small"
        label={children}
        variant="outlined"
        inputRef={ fullNameInput }
        value={ searchFullName }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleInputName(); 
          debouncedHandleInput(fullNameInput.current.value);
        }}
        // {(e: React.ChangeEvent<HTMLInputElement>) => handleInputSearch(e.target.value, children)}
        onKeyDown={(e:any) => handleSearchEnter( e, children )}

        InputProps={{
          endAdornment: (
            <InputAdornment position="end"
              sx={{ paddingLeft: "-2rem", paddingRight: "0rem", scale: "0.7"}}
            >
              { searchFullName && searchFullName !== '' && ( 
                <IconButton 
                  sx={{ paddingLeft: "0rem", paddingRight: "0.8rem" }}
                edge="end" onClick={() => handleClearFilter( children )}>
                  <Icon>clear_icon_component</Icon>
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />

      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "0rem",
          lineHeight: "0rem",
          scale: '0.9',
        }}
      >
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'asc') }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
        </MDBox>
        <MDBox
          sx={{ '&:hover': { opacity: 1, }, }}
          color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
          opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
          style={{ cursor: 'pointer' }}
          onClick={() => handleAscDesc( String(children), 'desc')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </MDBox>
      </MDBox>
    </MDBox>
) : children === 'Dátum udalosti' ? (
  <MDBox
    display="flex" flexDirection="row"
    right={align !== "right" ? "16px" : 0}
    left={align === "right" ? "-5px" : "unset"}
    sx={({ typography: { size } }: any) => ({
      fontSize: size.lg,
      width: "9.4rem",
    })}
  >
    <MDBox
      component="th"
      width={width}
      py={1.5}
      // px={2}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
      borderBottom: `${borderWidth[1]} solid ${light.main}`,      
      position: "relative",
      textAlign: "left",
      opacity: "0.7",
      background: "transparent",
      color: "#7b809a",
      fontSize: "0.75rem",
      fontWeight: "700",
      wordWrap:"unset",
      width: "100%",
      height: "100%"
      })}
    >
      Dátum udalosti
    </MDBox>  

    { searchOccurredAt && ( 
      <IconButton 
        sx={{ paddingLeft: "0rem", paddingRight: "0.5rem", }}
        edge="end" onClick={() => handleClearFilter( children )}>
        <Icon>clear_icon_component</Icon>
      </IconButton>
    )} 

    <MDBox 
      sx={{  
        height: "inherit", display: "flex", 
        alignItems: "center", justifyContent: "center", paddingLeft: "0.3rem", opacity: "0.7",
        "&:hover": {
          opacity: 1,
          cursor: "pointer",
        },
        }} >
      <IconButton 
        onClick={ () => handleDatePicker( children )}
        sx={{ 
          cursor: "pointer", padding: "0", 
        }}>
        <CalendarTodayIcon sx={{ cursor: "pointer" }} />
      </IconButton>
    </MDBox>  
   
    <MDBox
      sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "0rem",
      lineHeight: "0rem",
      scale: '0.9',
      }}
    >
      <MDBox
        sx={{ '&:hover': { opacity: 1, }, }}
        color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
        opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
        style={{ cursor: 'pointer' }}
        onClick={() => handleAscDesc( String(children), 'asc') }
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
        </svg>
      </MDBox>
      <MDBox
        sx={{ '&:hover': { opacity: 1, }, }}
        color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
        opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
        style={{ cursor: 'pointer' }}
        onClick={() => handleAscDesc( String(children), 'desc')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
      </MDBox>
    </MDBox>

  </MDBox>
) : children === 'Nahlásené' ? (
  <MDBox
    display="flex" flexDirection="row"
    right={align !== "right" ? "16px" : 0}
    left={align === "right" ? "-5px" : "unset"}
    sx={({ typography: { size } }: any) => ({
      fontSize: size.lg,
    })}
  >    
    <MDBox
      component="th"
      width={width}
      py={1.5}
      // px={2}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
      borderBottom: `${borderWidth[1]} solid ${light.main}`,      
      position: "relative",
      textAlign: "left",
      opacity: "0.7",
      background: "transparent",
      color: "#7b809a",
      fontSize: "0.75rem",
      fontWeight: "700",
      wordWrap:"unset",
      width: "100%"
      })}
    >
      Nahlásené
    </MDBox> 

    { searchReportedAt && ( 
      <IconButton 
        sx={{ paddingLeft: "0rem", paddingRight: "0.5rem" }}
        edge="end" onClick={() => handleClearFilter( children )}>
        <Icon>clear_icon_component</Icon>
      </IconButton>
    )} 

    <MDBox 
      sx={{  
        height: "inherit", display: "flex", 
        alignItems: "center", justifyContent: "center", paddingLeft: "0.3rem", opacity: "0.7",
        "&:hover": {
          opacity: 1,
          cursor: "pointer",
        }, }} >
      <IconButton 
        onClick={ () => handleDatePicker( children )}
        sx={{ 
          cursor: "pointer", padding: "0", 
        }}>
        <CalendarTodayIcon sx={{ cursor: "pointer" }} />
      </IconButton>
    </MDBox>  
 
    <MDBox
      sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "0rem",
      lineHeight: "0rem",
      scale: '0.9',
      }}
    >
      <MDBox
        sx={{ '&:hover': { opacity: 1, }, }}
        color={sortDirection === 'asc' && activeColumn === children ? 'text' : 'secondary'}
        opacity={sortDirection === 'asc' && activeColumn === children ? 1 : 0.5}
        style={{ cursor: 'pointer' }}
        onClick={() => handleAscDesc( String(children), 'asc') }
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
        </svg>
      </MDBox>
      <MDBox
        sx={{ '&:hover': { opacity: 1, }, }}
        color={sortDirection === 'desc' && activeColumn === children ? 'text' : 'secondary'}
        opacity={sortDirection === 'desc' && activeColumn === children ? 1 : 0.5}
        style={{ cursor: 'pointer' }}
        onClick={() => handleAscDesc( String(children), 'desc')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
      </MDBox>
    </MDBox>

  </MDBox>
) : children === 'Lokalita' ? (
  <MDBox
    className="overeneHlaseniaHeader"
    display="flex" flexDirection="row"
    right={align !== "right" ? "16px" : 0}
    left={align === "right" ? "-5px" : "unset"}
    sx={({ typography: { size } }: any) => ({
      fontSize: size.lg,
    })}
  >
    <MDBox
      component="th"
      width={width}
      py={1.5}
      // px={2}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
      borderBottom: `${borderWidth[1]} solid ${light.main}`,      
      position: "relative",
      textAlign: "left",
      opacity: "0.7",
      background: "transparent",
      color: "#7b809a",
      fontSize: "0.75rem",
      fontWeight: "700",
      wordWrap:"unset",
      width: "100%"
      })}
    >
      Lokalita
    </MDBox>
    
  </MDBox>
  ) : children === 'Action' ? (
  <MDBox
      component="th"
      width={width}
      // py={1.5}
      // px={2}
      sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
      // borderBottom: `${borderWidth[1]} solid ${light.main}`,  
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",    
      position: "relative",
      textAlign: "center",
      opacity: "0.7",
      background: "transparent",
      color: "#7b809a",
      fontSize: "2rem",
      fontWeight: "700",
      wordWrap:"unset",
      width: "100%",
      "&:hover": {
        opacity: 1, // Change opacity on hover
        cursor: "pointer",
      },
      })}
    >
      { searchValue || searchId || searchType || searchOccurredAt || searchStatus || searchFullName || searchReportedAt ? (
        
        <ClearIcon onClick={handleClear} />
      ) : null
      }
 
    </MDBox>
  ): (
    // Otherwise, show the existing content
    <>
      
    </>
  )}
      </MDBox>
    // </MDBox>
  );
}

// Declaring default props for DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

export default DataTableHeadCell;


    // "@mui/lab": "^5.0.0-alpha.138",
    //     "@asseinfo/react-kanban": "2.2.0",


//     "react-dom": ">=16.8.0",

// lock.json
//         "react": "18.2.0",
//         "react-dom": "18.2.0",
