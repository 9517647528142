

// types
import { ReportInfo } from 'types/interface'


// /*
// */ Data report
// 

// data report
export const extractReport = ( {
    id,
    type,
    status,
    reporter,
    location,
    photo,
    description,    
  }: any) => {
    let translateType = translationType( type );
    let translateStatus = translationStatus( status );
    let reporterFullName = reporter && reporter.fullName && (reporter.fullName !== 'Deleted User')  ? reporter.fullName : 'Zmazaný používateľ';
    let locationName = location && location.name ? location.name : null;
    let locationCoordinatesLatitude = location && location.coordinates.latitude ? location.coordinates.latitude : null;
    let locationCoordinatesLongitude = location && location.coordinates.longitude ? location.coordinates.longitude : null;
    let photoSmall = photo && photo.small ? photo.small : null;
    let photoLarge = photo && photo.large ? photo.large : null;
    let reportedIncidents = reporter && reporter.stats.reportedIncidents ? reporter.stats.reportedIncidents : null;
    let confirmedIncidents = reporter && reporter.stats.confirmedIncidents ? reporter.stats.confirmedIncidents : null;
    
    return { 
      id, 
      type: translateType, 
      status: translateStatus,
      fullName: reporterFullName,
      location: locationName,
      description, 
      locationLatitude: locationCoordinatesLatitude,
      locationLongitude: locationCoordinatesLongitude,
      photoSmall: photoSmall,
      photoLarge: photoLarge,
      reportedIncidents: reportedIncidents,
      confirmedIncidents: confirmedIncidents,    
    } as ReportInfo
  }

  // translate status eng => sk
  const translationStatus = ( status: string ) => {
    if ( status === 'pending' ) {
      return 'Čaká na schválenie';
    } else if ( status === 'confirmed' ) {
      return 'Schválené';
    } else if ( status === 'rejected' ) {
      return 'Zamietnuté';
    }
  }
  
  // translate type eng => sk
  const translationType = ( type: string ) => {
    if ( type === 'tracks' ) {
      return 'Stopy';
    } else if ( type === 'scat' ) {
      return 'Trus';
    } else if ( type === 'bear_tree' ) {
      return 'Medvedí strom';
    } else if ( type === 'encounter' ) {
      return 'Stret';
    }
  }
    
  
// export data
const Data = {
    extractReport,
  };
  
  export default Data;
