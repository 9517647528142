
import { format, parseISO } from 'date-fns'

// types
import { Reports } from "types/interface"


// /*
// */ Data table Header
// 

// Define the type for the 'columns' property
interface DataTableColumn {
  Header: string;
  accessor: string;
  width: string;
}

// Define the type for the 'dataTableHeader' object
interface DataTableHeader {
  columns: DataTableColumn[];
}

export const dataTableHeader: DataTableHeader = {
  columns: [
    { Header: "Id", accessor: "id", width: "7%" },
    { Header: "Meno", accessor: "fullName", width: "9%" },
    { Header: "Dátum vyjadrenia", accessor: "submittedAt", width: "17%" },
    { Header: "Vyjadrenie", accessor: "isValid", width: "10%" },
    { Header: "Popis", accessor: "explanation", width: "30%" },
  ],
};



export const extractVotes = ({
  user,
  submittedAt,
  isValid,
  explanation,
}: any) => {
  const { id, fullName } = user;
  const idValue = id;
  const userName = fullName;
  
  let dateTimesubmittedAt = parseISO(submittedAt);
  let formattedsubmittedAt = format(dateTimesubmittedAt, 'dd.MM.yyyy HH:mm');

  let translateIsValid = isValid === true ? 'Schválené' : 'Zamietnuté';  

  return {
    id: idValue,
    fullName: userName,
    submittedAt: formattedsubmittedAt,
    isValid: translateIsValid,
    explanation,
  };
};



// export data
const dataTableData = {
  dataTableHeader,
  extractVotes,
};

export default dataTableData;



