
import { format, parseISO } from 'date-fns'

// types
import { Reports } from "types/interface"


// /*
// */ Data table Header
// 

// Define the type for the 'columns' property
interface DataTableColumn {
  Header: string;
  accessor: string;
  width: string;
  canSort?: boolean;
}

// Define the type for the 'dataTableHeader' object
interface DataTableHeader {
  columns: DataTableColumn[];
}

export const dataTableHeader: DataTableHeader = {
  columns: [
    { Header: "Id", accessor: "id", width: "10%" },
    { Header: "Typ", accessor: "type", width: "9%" },
    { Header: "Lokalita", accessor: "location", width: "11%", canSort: false},
    { Header: "Dátum udalosti", accessor: "occurredAt", width: "9%" },
    { Header: "Stav", accessor: "status", width: "10%" },
    { Header: "Nahlasovateľ", accessor: "fullName", width: "15%"},
    { Header: "Nahlásené", accessor: "reportedAt", width: "9%" },
    { Header: "Action", accessor: "Action", width: "auto"},
  ],
};



export const extractData = ({
  id,
  type,
  location,
  occurredAt,
  reporter,
  status,
  reportedAt
}: Reports) => {
  const { coordinates, name } = location;
  const locationValue = name;

  let fullName = reporter && reporter.fullName && (reporter.fullName !== 'Deleted User')  ? reporter.fullName : 'Zmazaný používateľ';

  let translateStatus = translationStatus(status);
  let translateType = translationType(type);

  let dateTimeOccurredAt = parseISO(occurredAt);
  let formattedOccurredAt = format(dateTimeOccurredAt, 'dd.MM.yyyy HH:mm');

  let dateTimereportedAt = parseISO(reportedAt);
  let formattedreportedAt = format(dateTimereportedAt, 'dd.MM.yyyy HH:mm');

  return {
    id,
    type: translateType,
    location: locationValue,
    occurredAt: formattedOccurredAt,
    fullName,
    status: translateStatus,
    reportedAt: formattedreportedAt,
  };
};



// translate status eng => sk
const translationStatus = ( status: string ) => {
  if ( status === 'pending' ) {
    return 'Čaká na schválenie';
  } else if ( status === 'confirmed' ) {
    return 'Schválené';
  } else if ( status === 'rejected' ) {
    return 'Zamietnuté';
  }
}

// translate type eng => sk
const translationType = ( type: string ) => {
  if ( type === 'tracks' ) {
    return 'Stopy';
  } else if ( type === 'scat' ) {
    return 'Trus';
  } else if ( type === 'bear_tree' ) {
    return 'Medvedí strom';
  } else if ( type === 'encounter' ) {
    return 'Stret';
  }
}

// export data
const dataTableData = {
  dataTableHeader,
  extractData,
};

export default dataTableData;



