
// axios
import { apiClient } from "api-client"

import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { User } from 'types/index'

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

// Settings page components
import BasicInfo from "layouts/pages/user/addUser/components/BasicInfo";

function AddUser(): JSX.Element {

  const [ newId, setNewId ] = useState<number>( null );
  const [ name, setName ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const [ role, setRole ] = useState< string | null >('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isError, setIsError ] = useState<boolean>( false )
  const [ isNewId, setIsNewId ] = useState<boolean>( false )


  const navigate = useNavigate();

  // translate user role
  const handleAddUserRole = ( userRole: string ) => {
    console.log( userRole )
    if ( userRole && userRole === 'Odborník' ) {
      setRole( 'specialist' )
    } else if ( userRole && userRole === 'Administrátor' ) {
      setRole( 'admin' )
    } else {
      setRole('')
    }
  }

  // get name from child
  const handleAddUserName = ( userName: string ) => {
    if ( userName ) {
      setName(userName)
    } else {
      setName('')
    }
  }

  // get email from child
  const handleAddUserEmail = ( userEmail: string ) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let emailTest = emailRegex.test(userEmail)
    if ( emailTest ) {
      setEmail( userEmail )
    } else {
      setEmail('')
    }
  }

  // add user data
  const addUser = async () => {
    try {
      await apiClient.post('/v1/users',{
        email: email,
        fullName: name,
        role: role,
        }
      ).then( ( response: any ) => {       
        setNewId( response.data.id )
        setIsNewId( true )
        setIsError( false )
        console.log( response.data.id )
      });
    } catch ( error: any ){
      if ( error && error.response.status === 400 && error.response.data.code === 'UserEmailOccupiedError') {
        setErrorMessage(error.response.data.code);
        setIsError( true )
        console.log( error.message );
      } else {
        console.log( error.response.data.code , 'else' );
        setErrorMessage(error.response.data.code);
        // setIsError( true )
      }
    }
  }  

  // if click btn add, put data to server
  const handleSubmitAdd = ( verify: string ) => {
    if ( verify === 'true' && name && email && role ) { 
      addUser()      
    }
  }

   // go to user profile
   useEffect( () => {
    if ( newId !== null && isNewId ) {
      backProfile()
      setIsNewId( false )
    }
  }, [ newId, isNewId ]);
  

  // go to new user profile page
  const backProfile = () => {
    const id = newId;
    navigate(( `/pages/user/userProfile/${ newId }` ), { state:{ id } });
  };

  const handleNotClick = ( bool: boolean ) => {
    if ( bool === true ) setIsError( false )
  }

  const alertContent = (name: string) => (
    <MDTypography variant="body2" color="white">
      
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      Používateľ so zadaným e-mailom už existuje. Použite iný e-mail
      </MDTypography>
      {/* . Give it a click if you like. */}
    </MDTypography>
  );

  return (
    <MDBox 
      sx={{ marginLeft: "max(18.7rem)", paddingRight: "1rem", width: "auto",
        display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "1rem"
      }}
    >     
      
      <MDBox 
        sx={{ width: "27rem", display: "flex", flexDirection: "column", alignItems: "center", }}
      >

        {isError && 
        <MDAlert 
          sx={{ 
            width: "132%"
          }}
          color="error" 
          dismissible
          onNotClick={handleNotClick}
          >
          {alertContent("error")}
        </MDAlert>
        }
        
        <Grid item >
          <BasicInfo 
            onAddUserName={handleAddUserName}
            onAddUserEmail={handleAddUserEmail}
            onAddUserRole={handleAddUserRole}
            onSubmitAdd={handleSubmitAdd}
            name={ name }
            email={ email }
            role={ role }
          />
        </Grid>                
          
      </MDBox>

    </MDBox>   
  );
}

export default AddUser;
