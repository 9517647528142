
import React, { useRef } from 'react';

import { useNavigate } from "react-router-dom";

import DOMPurify from "dompurify";

// types
import { User } from 'types/index'


// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDButton from "components/MDButton";


// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/user/components/FormField";

// Data
import selectData from "layouts/pages/user/addUser/components/BasicInfo/data/selectData";

interface Props {
  user?: User,
  name?: string,
  email?: string,
  role?: string,
  onAddUserName: (name: string) => void,
  onAddUserEmail: (email: string) => void,
  onAddUserRole: (role: string) => void,
  onSubmitAdd: ( verify: string ) => void,
}

function  BasicInfo({ 
  user,
  name,
  email,
  role,
  onAddUserName, 
  onAddUserEmail, 
  onAddUserRole, 
  onSubmitAdd }: Props): JSX.Element {

  const navigate = useNavigate();

  const nameInput = useRef<HTMLInputElement>(null)
  const emailInput = useRef<HTMLInputElement>(null)
  const roleInput = useRef<HTMLInputElement>(null)

  const submitAdd = ( e: any) => {
    // e.preventDefault();
    onSubmitAdd( 'true' );
  }
  
  // get data from name input
  const getInputName = () => {
    let userName = DOMPurify.sanitize(nameInput.current?.value);
    onAddUserName(userName)
  }
  
  // get data from email input
  const getInputEmail = () => {
    let userEmail = DOMPurify.sanitize(emailInput.current?.value);
    if ( userEmail ) onAddUserEmail(userEmail)
  }

  // get data from role input
  const getInputRole = ( e:any, newValue: any ) => {
    let role = DOMPurify.sanitize(newValue)
    onAddUserRole(role)
  }

  // back to table-users page
  const backTable = () => {
    navigate('/layouts/users-list');
  }; 

  const isVisible = name && email && role;

  return (
    
    <Card id="basic-info" 
      sx={{ overflow: "visible", width: "100%"}} >
      <MDBox p={3}>
        <MDTypography variant="h5">Osobné Informácie</MDTypography>
      </MDBox>      

      <MDBox container pb={3} px={3}>
        <Grid container spacing={3}>           

          <Grid item xs={12} md={12} >
            <MDTypography variant="button" >
              Meno
            </MDTypography>
            <FormField 
              placeholder="Meno používateľa"
              inputRef={nameInput}
              onChange={getInputName}
            />
          </Grid>

          <Grid item xs={12} md={12} >
            <MDTypography variant="button" >
              E-mail
            </MDTypography>
            <FormField 
              name="email"
              placeholder="E-mail používateľa"
              inputProps={{ type: "Email" }}
              inputRef={emailInput}
              onChange={getInputEmail}
            />
          </Grid>
                 
          <Grid item xs={12} md={12} 
            sx={ {                         
              mt: "0.6rem"
            } }>
            <MDTypography 
              variant="button" >
              Rola
            </MDTypography>  
            <Autocomplete             
              options={selectData.roleUser}
              onChange={getInputRole}
              renderInput={(params) => (
                <FormField {...params} 
                  InputLabelProps={{ shrink: true }}
                  inputRef={roleInput} 
                  placeholder="Rola používateľa"
                /> )}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox container 
        sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", 
            gap: "1rem", paddingRight: "1rem", paddingBottom: "1rem",
        }}       
      >   
        <MDButton 
          variant="outlined" 
          color="success" 
          sx={{ 
            height: "50%",                
        }}    
          onClick={() => navigate(-1)}              
          >
          naspäť
        </MDButton>        

        <MDButton 
          variant="gradient" 
          color="success" 
          sx={{ height: "50%",
            visibility: isVisible ? "visible" : "hidden", 
          }}
          onClick={submitAdd}
          >
          Vytvoriť
        </MDButton>

      </MDBox>     

    </Card>
  );
}

export default BasicInfo;
